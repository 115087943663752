import {
  WppActionButton,
  WppCard,
  WppIconEdit,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import styles from 'pages/project/components/projectDashboard/ProjectDashboard.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { capitalizeFirstLetter, fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'

const findType = (nodeType: HierarchyLevelType, nodes: NavigationTreeNode[]) => {
  return nodes.find(({ type, customTypeName }) => {
    return (
      nodeType.toLowerCase() ===
      (type.toLowerCase() === HierarchyCustomNodeType.toLowerCase()
        ? customTypeName?.toLowerCase()
        : type.toLowerCase())
    )
  })
}

const NotDefined = () => (
  <WppTypography type="l-strong" className={styles.notDefine}>
    -
  </WppTypography>
)

export const ProjectInfo = () => {
  const { t } = useTranslation()
  const { project, members, isInactive } = useProject()
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()
  const { getWorkspaceTree } = useHierarchy()
  const { hierarchyOrder } = useHierarchy()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { contextWorkspace, description, type, processType, createdAt, startDate, endDate } = project
  const workspaceData = getWorkspaceTree(contextWorkspace)

  const owners = useMemo(
    () => members.map(member => (member.role === ProjectRole.OWNER ? member : null)).filter(Boolean),
    [members],
  )

  const isWorkspaceValid = !!contextWorkspace && !!workspaceData.length
  const hierarchy = contextWorkspace === null || isWorkspaceValid

  return (
    <WppCard className={styles.detailsCard} size="l" data-testid="project-details-card">
      <WppTypography slot="header" type="l-strong">
        {t('overview.project_details')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        {!hierarchy && (
          <WppTooltip text={t('project.about.incorrect_workspace')}>
            <WorkspaceWarning contextHierarchy={project.contextHierarchy} />
          </WppTooltip>
        )}
        <Flex gap={12}>
          <Flex direction="column" className={styles.tagBlock} data-testid="project-type-details">
            <WppTooltip text={t('overview.project_type')!}>
              <ProjectTypeTag type={type} />
            </WppTooltip>
          </Flex>

          <Flex direction="column" data-testid="project-process-type">
            <WppTooltip text={t('overview.process_type')!}>
              <WppTag label={t(`overview.process_type_${processType}`)!} variant="neutral" />
            </WppTooltip>
          </Flex>
        </Flex>

        {isWorkspaceValid && (
          <Flex gap={80}>
            {hierarchyOrder.map(hierarchy => {
              return (
                <Flex key={hierarchy} direction="column" data-testid={`project-${hierarchy}`}>
                  <WppTypography type="s-midi" className={styles.label}>
                    {t(`overview.${hierarchy}`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    })}
                  </WppTypography>
                  {findType(hierarchy.toUpperCase() as HierarchyLevelType, workspaceData) ? (
                    <WppTypography type="m-strong">
                      {findType(hierarchy.toUpperCase() as HierarchyLevelType, workspaceData)?.name}
                    </WppTypography>
                  ) : (
                    <NotDefined />
                  )}
                </Flex>
              )
            })}
          </Flex>
        )}
        {!!description && (
          <Flex direction="column" data-testid="project-goals-and-description">
            <WppTypography type="s-midi" className={styles.label}>
              {t('overview.goals_and_description')}
            </WppTypography>
            <WppTypography type="m-body">{description}</WppTypography>
          </Flex>
        )}

        <Flex direction="column" data-testid="project-members-info" gap={5}>
          <WppTypography type="s-midi" className={styles.label}>
            {t('project.members_card.project_owners')}
          </WppTypography>
          <div className={styles.grid}>
            {owners.map(owner => (
              <Flex key={owner?.email} gap={8} align="center" data-testid="member-info-owner">
                <Avatar
                  data-testid="member-avatar"
                  className={styles.avatar}
                  size="s"
                  name={fullName(owner?.firstname, owner?.lastname)}
                  src={owner?.avatarUrl ?? ''}
                />

                <Flex direction="column">
                  <Truncate
                    lines={1}
                    type="s-strong"
                    title={fullName(owner?.firstname, owner?.lastname)}
                    data-testid="member-fullname"
                  >
                    {fullName(owner?.firstname, owner?.lastname)}
                  </Truncate>
                  <Link to={`mailto: ${owner?.email}`} className={styles.emailIcon}>
                    <Truncate lines={1} type="s-body" title={owner?.email}>
                      {owner?.email}
                    </Truncate>
                  </Link>
                </Flex>
              </Flex>
            ))}
          </div>
        </Flex>

        <Flex gap={40}>
          {(startDate || endDate) && (
            <>
              <Flex direction="column" data-testid="project-start-date">
                <WppTypography type="s-midi" className={styles.label}>
                  {t('common.start_date')}
                </WppTypography>

                {startDate ? (
                  <WppTypography type="m-strong">{formatDate({ dateString: startDate, dateLocale })}</WppTypography>
                ) : (
                  <NotDefined />
                )}
              </Flex>
              <Flex direction="column" data-testid="project-end-date">
                <WppTypography type="s-midi" className={styles.label}>
                  {t('common.end_date')}
                </WppTypography>

                {endDate ? (
                  <WppTypography type="m-strong">{formatDate({ dateString: endDate, dateLocale })}</WppTypography>
                ) : (
                  <NotDefined />
                )}
              </Flex>
            </>
          )}
          {!startDate && !endDate && (
            <Flex direction="column" data-testid="project-creation-date">
              <WppTypography type="s-midi" className={styles.label}>
                {t('common.created_on')}
              </WppTypography>

              <WppTypography type="m-strong">
                {formatDate({ dateString: createdAt, dateLocale, relativeFormatting: true })}
              </WppTypography>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOwnerOrGlobalManage && !isInactive && (
        <div slot="actions">
          <WppActionButton
            slot="trigger-element"
            variant="secondary"
            onClick={() => showProjectEditModal({ project: project })}
          >
            <WppIconEdit slot="icon-start" />
          </WppActionButton>
        </div>
      )}
    </WppCard>
  )
}
