import { MayBeNull } from '@wpp-open/core'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { usePatchActivityApi } from 'api/canvas/mutation/usePatchActivityApi'
import { usePatchApplicationApi } from 'api/canvas/mutation/usePatchApplicationApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { getDate, ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { LinearData } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { LOAD_ALL } from 'providers/project/ProjectProvider'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus } from 'types/projects/tasks'
import {
  ActivityApplicationItem,
  ActivityItem,
  ApplicationItem,
  FluidWorkflow,
  PhaseItem,
  PhaseItemType,
} from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'

interface Props {
  id: string
  type: PhaseItemType
  name?: string
  projectId?: string
  processType?: ProcessType
}

interface WorkflowDataContext<T> {
  previousData?: { data: T }
}

export const useUpdateItem = ({ id, type, name, projectId, processType = ProcessType.LINEAR }: Props) => {
  const { mutateAsync: handlePatchActivityLinear } = usePatchActivityApi({
    onMutate: async newActivity => {
      const { id: itemId, startDate, endDate, assignUser, taskStatus } = newActivity
      const projectQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectQueryKey)

      const previousData = queryClient.getQueryData<{ data: LinearData }>(projectQueryKey)

      if (previousData) {
        const tasks = previousData.data.items as Record<string, PhaseItem>

        const updatedData = Object.entries(tasks).map(([taskId, task]) => {
          if (task.itemType === PhaseItemType.Activity) {
            const updatedTask = updateApplicationItem({
              item: task.item as ActivityItem,
              id: itemId,
              startDate,
              endDate,
              assignUser,
              taskStatus,
            })
            return [taskId, { ...task, item: updatedTask }]
          }
          // If the task's is not activity it should be skipped
          return [taskId, task]
        })

        const updatedTasks = Object.fromEntries(updatedData)

        const newData = { ...previousData, data: { ...previousData.data, tasks: updatedTasks } }

        queryClient.setQueryData(projectQueryKey, newData)
      }

      return { previousData }
    },
    onError: (_err, _, context) => {
      const typedContext: WorkflowDataContext<LinearData> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], typedContext.previousData)
      }
    },
  })

  const { mutateAsync: handlePatchAppLinear } = usePatchApplicationApi({
    onMutate: async newApp => {
      const { id: itemId, startDate, endDate, assignUser, taskStatus } = newApp

      const projectTasksQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectTasksQueryKey)

      const previousData = queryClient.getQueryData<{ data: LinearData }>(projectTasksQueryKey)

      if (previousData) {
        if (previousData) {
          const tasks = previousData.data.items as Record<string, PhaseItem>

          const updatedData = Object.entries(tasks).map(([taskId, task]) => {
            if (task.itemType === PhaseItemType.Application) {
              const updatedTask = updateApplicationItem({
                item: task.item as ApplicationItem,
                id: itemId,
                startDate,
                endDate,
                assignUser,
                taskStatus,
              })

              return [taskId, { ...task, item: updatedTask }]
            }

            //update app inside activity
            if (task.itemType === PhaseItemType.Activity) {
              const activityItems = task.item as ActivityItem
              const updatedActivityItems = activityItems.items.map((activityItem: ActivityApplicationItem) => {
                const updatedApplication = updateApplicationItem({
                  item: activityItem.application,
                  id: itemId,
                  startDate,
                  endDate,
                  assignUser,
                  taskStatus,
                })
                return { ...activityItem, application: updatedApplication }
              })

              const updatedActivityItem = {
                ...task.item,
                items: updatedActivityItems,
              }

              return [taskId, { ...task, item: updatedActivityItem }]
            }
            // If the task's is not activity it should be skipped
            return [taskId, task]
          })

          const updatedTasks = Object.fromEntries(updatedData)

          const newData = { ...previousData, data: { ...previousData.data, tasks: updatedTasks } }

          queryClient.setQueryData(projectTasksQueryKey, newData)
        }
      }

      return { previousData }
    },
    onError: (_err, _, context) => {
      const typedContext: WorkflowDataContext<LinearData> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], typedContext.previousData)
      }
    },
  })

  const { mutateAsync: handlePatchAppFluid } = usePatchApplicationApi({
    onMutate: async newApp => {
      const { id: itemId, startDate, endDate, assignUser, taskStatus } = newApp

      const projectTasksQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]
      await queryClient.cancelQueries(projectTasksQueryKey)

      const previousData = queryClient.getQueryData<{ data: FluidWorkflow }>(projectTasksQueryKey)

      if (previousData) {
        const updatedItems = previousData.data.items.map(item => {
          const applicationItem = item as ApplicationItem
          const updatedApplication = updateApplicationItem({
            item: applicationItem,
            id: itemId,
            startDate,
            endDate,
            assignUser,
            taskStatus,
          })

          const updatedItem = {
            ...applicationItem,
            ...updatedApplication,
          }
          return updatedItem
        })

        const newData = {
          ...previousData,
          data: { ...previousData.data, items: updatedItems },
        }

        queryClient.setQueryData(projectTasksQueryKey, newData)
      }
      return { previousData }
    },

    onError: (_err, _, context) => {
      const typedContext: WorkflowDataContext<FluidWorkflow> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], typedContext.previousData)
      }
    },
  })

  const { mutateAsync: handlePatchActivityFluid } = usePatchActivityApi({
    onMutate: async newApp => {
      const { id: itemId, startDate, endDate, assignUser, taskStatus } = newApp

      const projectTasksQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]
      await queryClient.cancelQueries(projectTasksQueryKey)

      const previousData = queryClient.getQueryData<{ data: FluidWorkflow }>(projectTasksQueryKey)

      if (previousData) {
        const updatedItems = previousData.data.items.map(item => {
          const activityItem = item as ActivityItem
          const updatedApplication = updateApplicationItem({
            item: activityItem,
            id: itemId,
            startDate,
            endDate,
            assignUser,
            taskStatus,
          })

          const updatedItem = {
            ...activityItem,
            ...updatedApplication,
          }
          return updatedItem
        })

        const newData = {
          ...previousData,
          data: { ...previousData.data, items: updatedItems },
        }

        queryClient.setQueryData(projectTasksQueryKey, newData)
      }
      return { previousData }
    },

    onError: (_err, _, context) => {
      const typedContext: WorkflowDataContext<FluidWorkflow> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], typedContext.previousData)
      }
    },
  })

  const { showToast } = useToast()
  const { t } = useTranslation()

  const updateItem = async ({
    dates,
    assignUser,
    status,
    hidden,
  }: Partial<{
    dates: Date[]
    assignUser: MayBeNull<ResponsibleUser>
    status: TaskStatus
    hidden: boolean
  }>) => {
    const updateAction =
      type === PhaseItemType.Application
        ? processType === ProcessType.LINEAR
          ? handlePatchAppLinear
          : handlePatchAppFluid
        : processType === ProcessType.LINEAR
        ? handlePatchActivityLinear
        : handlePatchActivityFluid
    try {
      let body: Parameters<typeof updateAction>[0] = {
        id,
      }
      if (dates) {
        const stringDates = dates.map(date => format(date, projectDateFormat))
        body = {
          ...body,
          ...getDate(stringDates),
        }
      }

      // `assignUser` is a nullable object, but we shouldn't erase it if this field is not presented in DTO
      if (assignUser !== void 0) {
        body = {
          ...body,
          assignUser: assignUser?.email || null,
        }
      }

      if (status) {
        body = {
          ...body,
          taskStatus: status,
        }
      }

      const isHidden = hidden !== undefined && hidden !== null

      if (isHidden) {
        body = {
          ...body,
          hidden,
        }
      }

      updateMembersData({ projectId: projectId!, assignUser })
      await updateAction(body)

      const toastMessage = isHidden
        ? hidden
          ? 'project.canvas.toast.update_hidden_off'
          : 'project.canvas.toast.update_hidden_on'
        : type === PhaseItemType.Application
        ? 'project.canvas.toast.update_app'
        : 'project.canvas.toast.update_activity'
      await invalidateCanvas(!assignUser?.isMember)
      showToast({ type: 'success', message: t(toastMessage, { query: makeStringShorter(name) }) })
    } catch (e) {
      const toastMessage = type === PhaseItemType.Application ? PhaseItemType.Application : PhaseItemType.Activity
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_edit', { query: toastMessage }),
      })
      console.error(e)
    }
  }

  return { updateItem }
}

const updateApplicationItem = ({
  item,
  id,
  startDate,
  endDate,
  assignUser,
  taskStatus,
}: {
  item: ApplicationItem | ActivityItem
  id: string
  startDate?: MayBeNull<string> | undefined
  endDate?: MayBeNull<string> | undefined
  assignUser?: string | null
  taskStatus?: MayBeNull<TaskStatus>
}) => {
  if (item.id !== id) return item
  const updatedItem = {
    ...item,
    startDate: startDate !== undefined ? startDate : item.startDate,
    endDate: endDate !== undefined ? endDate : item.endDate,
    assignUser: assignUser !== undefined ? assignUser : item.assignUser,
    task: {
      ...item.task,
      status: taskStatus ?? item.task?.status,
    },
  }

  return updatedItem
}

export const updateMembersData = ({
  projectId,
  assignUser,
}: {
  projectId: string
  assignUser?: MayBeNull<ResponsibleUser>
}) => {
  if (assignUser?.isMember) return
  const projectMembersData = [ApiQueryKeys.MEMBERS, { id: projectId, itemsPerPage: LOAD_ALL }]
  const previousMembersData = queryClient.getQueryData<{ data: { data: Members[] } }>(projectMembersData)

  if (previousMembersData && assignUser?.email) {
    // Create a new structure with the updated assignUser
    const newData = {
      ...previousMembersData,
      data: {
        ...previousMembersData.data,
        data: [...previousMembersData.data.data, { ...assignUser, projectId, role: ProjectRole.VIEWER }],
      },
    }
    // Set the query data to the new structure
    queryClient.setQueryData(projectMembersData, newData)
  }
}
