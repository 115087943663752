import { HierarchyLevelType, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { capitalizeFirstLetter } from 'utils/common'

const mapToOptions = (nodes: NavigationTreeNode[] = []): FormSelectOption[] => {
  const mapValues = nodes.reduce((previousValue: Record<string, string[]>, currentValue: NavigationTreeNode) => {
    const { name } = currentValue

    if (!name) return previousValue
    return { ...previousValue, [name]: [...(previousValue[name] || []), currentValue.azId] as string[] }
  }, {})

  return Object.keys(mapValues)
    .map(key => ({ value: mapValues[key].join(','), label: key }))
    .sort((a, b) => a.label?.localeCompare(b.label))
}

export const HierarchyFilterControl = () => {
  const { t } = useTranslation()
  const { osContext } = useOs()
  const { mapping } = osContext.navigationTree

  const { hierarchyOrder } = useHierarchy()

  const mappedTree = useMemo(
    () =>
      Object.keys(mapping).reduce((previousValue, currentValue) => {
        const element = mapping[currentValue]

        const type = element.type === HierarchyCustomNodeType ? element.customTypeName : element.type

        // @ts-ignore
        return { ...previousValue, [type]: [...(previousValue[type] || []), element] }
      }, {} as Record<HierarchyLevelType, NavigationTreeNode[]>),
    [mapping],
  )

  const options = useMemo(() => {
    return Object.entries(mappedTree).reduce((prev, [key, nodes]) => {
      return { ...prev, [key.toLowerCase()]: mapToOptions(nodes) }
    }, {} as Record<HierarchyLevelType, FormSelectOption[]>)
  }, [mappedTree])

  return (
    <>
      {hierarchyOrder?.map(hierarchy => {
        return (
          <FormSelect
            key={hierarchy}
            name={hierarchy}
            withSearch
            className={styles.hierarchySelect}
            type="multiple"
            data-testid={`${hierarchy.toLowerCase()}-select`}
            options={options?.[hierarchy as HierarchyLevelType] || []}
            labelConfig={{
              text: t(`modals.dashboard_filters.field_${hierarchy.toLowerCase()}_label`, {
                defaultValue: capitalizeFirstLetter(hierarchy),
              }),
            }}
            placeholder={
              t(`modals.create_project.field_${hierarchy.toLowerCase()}_placeholder`, {
                defaultValue: capitalizeFirstLetter(hierarchy),
              })!
            }
            withFolder
            required
          />
        )
      })}{' '}
    </>
  )
}
