import {
  WppActionButton,
  WppButton,
  WppIconApp,
  WppIconPair,
  WppIconPin,
  WppIconPlus,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ReactFlowProvider } from 'reactflow'

import { Flex } from 'components/common/flex/Flex'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/canvas/Canvas.module.scss'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { CanvasActionsSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/CanvasActionsSkeleton'
import { FluidSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/FluidSkeleton'
import { LockOverlay } from 'pages/project/components/canvas/components/lockOverlay/LockOverlay'
import { showSaveAsTemplateModal } from 'pages/project/components/canvas/components/saveAsTemplateModal/SaveAsTemplateModal'
import FluidCanvas from 'pages/project/components/canvas/fluidCanvas/FluidCanvas'
import { useCanvasLock } from 'pages/project/components/canvas/hooks/useCanvasLock'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'

export const FluidTab = () => {
  const { project, fluidData, isFluidLoading, isInactive } = useProject()
  const { t } = useTranslation()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const canCreateTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE)

  const { isLocked, isLockLoading } = useCanvasLock(project.id)

  const isLockedPrevious = usePrevious(isLocked)

  useEffect(() => {
    if (isLockedPrevious === true && isLocked === false) {
      invalidateCanvas()
    }
  }, [isLocked, isLockedPrevious])

  const openAppPickerModal = async () => {
    showAppPickerModal({
      projectId: project.id,
      selectedCanvas: project.processType,
    })
  }

  const saveAsTemplate = () => {
    showSaveAsTemplateModal()
  }

  const addActivityModal = () => {
    showAddEditActivityModal({
      projectId: project.id,
      selectedCanvas: ProcessType.FLUID,
    })
  }

  const isLoading = isFluidLoading || isLockLoading
  const isSaveTemplateDisabled = isLoading || (project.processType === ProcessType.FLUID && !fluidData?.items?.length)

  return (
    <>
      <Flex direction="column" className={styles.bodyFluid}>
        <Flex justify="end">
          {isLoading ? (
            <CanvasActionsSkeleton />
          ) : (
            <>
              {isOwnerOrGlobalManage && (
                <Flex gap={12}>
                  {canCreateTemplate && (
                    <WppActionButton
                      onClick={saveAsTemplate}
                      disabled={isSaveTemplateDisabled}
                      data-testid="save-as-template-button"
                    >
                      <WppIconPin slot="icon-start" />
                      {t('project.canvas.btn_save_as_template')}
                    </WppActionButton>
                  )}

                  <WppMenuContext data-testid="context-add" className={styles.fluidCtxMenu}>
                    <WppButton size="s" slot="trigger-element" disabled={isInactive} data-testid="add-item-button">
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppButton>
                    <div>
                      <WppListItem onWppChangeListItem={() => openAppPickerModal()} data-testid="context-add-app">
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem onWppChangeListItem={addActivityModal} data-testid="context-add-activity">
                        <WppIconPair slot="left" />
                        <p slot="label">{t('project.canvas.activity')}</p>
                      </WppListItem>
                    </div>
                  </WppMenuContext>
                </Flex>
              )}
            </>
          )}
        </Flex>
        {isLoading ? (
          <FluidSkeleton />
        ) : (
          <>
            {isLocked && <LockOverlay />}
            {fluidData && (
              <ReactFlowProvider>
                <FluidCanvas projectId={project.id} fluidData={fluidData} isInactive={isInactive} />
              </ReactFlowProvider>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
