import { UserDetails, MayBeNull } from '@wpp-open/core'

import { LAUNCH_LOCATIONS } from 'constants/analytics'
import { LinearData } from 'pages/project/components/canvas/utils'
import { Project } from 'types/projects/projects'
import { ApplicationItem, ExternalAppVersion } from 'types/projects/workflow'

interface Props {
  contextHierarchyMapping: { [key: string]: string }
  application: ApplicationItem
  userDetails: UserDetails
  isNoCodeApp: boolean
  selectedVersion: ExternalAppVersion | undefined
  project: Project
  phaseId: MayBeNull<string | undefined>
  linearData: LinearData
}

export const getEventTrackParams = ({
  contextHierarchyMapping,
  application,
  userDetails,
  isNoCodeApp,
  selectedVersion,
  project,
  phaseId,
  linearData,
}: Props) => {
  return {
    source: 'ORCHESTRATION',
    launchedWithContext: !!(
      contextHierarchyMapping['client'] ||
      contextHierarchyMapping['market'] ||
      contextHierarchyMapping['brand']
    ),
    client: contextHierarchyMapping['client'] || '',
    market: contextHierarchyMapping['market'] || '',
    brand: contextHierarchyMapping['brand'] || '',
    productName: application.externalAppName,
    productType: application.type,
    appId: application.id || '',
    userId: userDetails.id,
    userEmail: userDetails.email,
    versionId: !isNoCodeApp ? selectedVersion?.id || '' : '',
    versionName: !isNoCodeApp ? selectedVersion?.name || '' : '',
    versionType: !isNoCodeApp ? selectedVersion?.versionType || '' : '',
    projectId: project?.id || '',
    projectName: project?.name || '',
    projectStatus: project?.status || '',
    projectType: project?.type || '',
    projectProcessType: project?.processType || '',
    phaseId: phaseId || '',
    phaseName: phaseId ? linearData.phases[phaseId].name : '',
    taskId: application.task?.id || '',
    taskStatus: application.task?.status || '',
    launchedFrom: LAUNCH_LOCATIONS.ProjectWorkflow,
  }
}
