import { HierarchyLevelType, MayBeNull, NavigationTree, Tenant } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'

import { useProject } from 'hooks/useProject'
import { DefaultHierarchyLevelType } from 'types/core'
import { NativeAppVersionDTO } from 'types/products/nativeApp'
import { AppIssueEnum } from 'types/projects/application'
import { Project } from 'types/projects/projects'
import { ApplicationItem } from 'types/projects/workflow'
import { isValidHierarchyLevel } from 'utils/validateAppContext'

export interface AppLaunchError {
  issue: AppIssueEnum
  appName: string
  missedCtx?: string[]
}

interface ContextParams {
  project: MayBeNull<Project>
  tenant: Tenant
  navigationTree: NavigationTree
}

/*
dataContext: ['BRAND']  Client -> Market -> Brand -- valid
dataContext: ['Market'] Client -> Market -> Brand -- invalid, Client -> Market -- valid
dataContext: ['TENANT'] without workspace -- valid, with any workspace -- invalid
dataContext: [] - any workspace lvl is valid
*/
function validateAppContext(
  contextParams: ContextParams,
  appName: string,
  actualVersion?: MayBeNull<NativeAppVersionDTO>,
) {
  const { project, navigationTree } = contextParams

  const currentWorkspaceLevel = !project?.contextWorkspace
    ? DefaultHierarchyLevelType.Tenant
    : (navigationTree.mapping[project?.contextWorkspace]?.type as HierarchyLevelType)

  const isValid = isValidHierarchyLevel(actualVersion, currentWorkspaceLevel)
  const missingLevels = isValid
    ? []
    : (actualVersion?.dataContext ?? []).filter(level => level !== currentWorkspaceLevel)

  return { isValid, missingLevels }
}

export const useNativeAppsErrors = (apps: { id: string; name: string; versions: NativeAppVersionDTO[] }[]) => {
  const {
    osContext: { tenant, navigationTree },
  } = useOs()
  const projectContext = useProject()

  // @TODO that's a temporary workaround. All project-coupled logic should be move out of common components
  const project = projectContext ? projectContext.project : null
  const contextParams = { project, tenant, navigationTree }

  return apps.reduce((acc, { id, name, versions }) => {
    acc[id] = versions.flatMap(version => {
      const ctxStatus = validateAppContext(contextParams, name, version)
      const errors: AppLaunchError[] = []

      if (!version) {
        errors.push({
          issue: AppIssueEnum.NotPublished,
          appName: name,
        })
      }

      if (!ctxStatus.isValid) {
        errors.push({
          issue: AppIssueEnum.WrongContext,
          missedCtx: ctxStatus.missingLevels,
          appName: name,
        })
      }
      return errors
    })

    return acc
  }, {} as { [appId: string]: AppLaunchError[] })
}

export const useNativeAppErrors = (id: string, name: string, versions: NativeAppVersionDTO[]): AppLaunchError[] => {
  return useNativeAppsErrors([{ id, name, versions }])[id] ?? []
}

export const useAppLaunchErrors = (application: MayBeNull<ApplicationItem>): AppLaunchError[] => {
  const {
    osContext: { tenant, navigationTree },
  } = useOs()
  const projectContext = useProject()

  if (!application) {
    return []
  }

  // @TODO that's a temporary workaround. All project-coupled logic should be move out of common components
  const project = projectContext ? projectContext.project : null
  const contextParams = { project, tenant, navigationTree }

  const appName = application.name!
  const errors: AppLaunchError[] = []
  const { isDeleted, isAvailable } = application
  const isPublished = application.externalAppVersions?.length > 0
  const actualVersion = application.externalAppVersions?.find(({ id }) => id === application.externalAppVersionId)
  const ctxStatus = validateAppContext(contextParams, appName, actualVersion)

  if (isDeleted) {
    errors.push({
      issue: AppIssueEnum.Deleted,
      appName,
    })
    return errors
  }

  if (!isPublished) {
    errors.push({
      issue: AppIssueEnum.NotPublished,
      appName,
    })
  }

  if (!isAvailable) {
    errors.push({
      issue: AppIssueEnum.NotAvailable,
      appName,
    })
  }

  if (!ctxStatus.isValid) {
    errors.push({
      issue: AppIssueEnum.WrongContext,
      missedCtx: ctxStatus.missingLevels,
      appName,
    })
  }

  if (!application.appInstanceId) {
    errors.push({
      issue: AppIssueEnum.NoInstance,
      appName,
    })
  }

  return errors
}
