import {
  WppCard,
  WppIconBranch,
  WppIconWarning,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useNativeAppErrors } from 'hooks/application/useAppErrors'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { showAppCreateIssuesModal } from 'pages/project/components/canvas/components/appPikerModal/appCreateIssuesModal/AppCreateIssuesModal'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { NativeAppsCategoryLabel } from 'pages/project/components/canvas/components/appPikerModal/utils'
import {
  SelectVersion,
  SelectVersionRef,
  SELECT_ITEM_CLASS_TRIGGER,
  SELECT_VERSION_CLASS_TRIGGER,
} from 'pages/project/components/canvas/components/appPikerModal/versions/SelectVersion'
import { NativeAppDTO, NativeAppType, NativeAppVersionDTO } from 'types/products/nativeApp'

interface Props {
  app: NativeAppDTO
  isMiroEnabled: boolean
  selectedVersions: NativeAppVersionDTO[]
  onSelectVersions: (version: NativeAppVersionDTO[]) => void
}

export const NativeAppCard = ({ app, selectedVersions, onSelectVersions, isMiroEnabled }: Props) => {
  const { t } = useTranslation()
  const versionSelectorRef = useRef<SelectVersionRef>(null)
  const [showVersionSelector, toggleVersionSelector] = useToggle(!!selectedVersions.length)

  // only Miro apps can be disabled here (in case when user has no valid Miro token)
  const isDisabled = app.type === NativeAppType.MIRO_BOARD ? !isMiroEnabled : false

  // Sorting function based on custom order
  const sortByCustomOrder = useCallback((a: NativeAppVersionDTO, b: NativeAppVersionDTO) => {
    const order: Record<string, number> = {
      PRODUCTION: 1,
      DEMO: 2,
      DEVELOPMENT: 3,
    }
    return order[a.versionType] - order[b.versionType]
  }, [])

  const sortedOptions = useMemo(() => app.versions!.sort(sortByCustomOrder), [app.versions, sortByCustomOrder])

  const allAppVersions = useMemo(() => app.versions ?? [], [app.versions])
  const appErrors = useNativeAppErrors(app.id, app.name, selectedVersions.length ? selectedVersions : allAppVersions)

  return (
    <div
      key={app.id}
      onClick={event => {
        if (isDisabled) return

        // toggle if only 1 version
        if (app.versions?.length === 1) {
          onSelectVersions(selectedVersions.length ? [] : app.versions)
          return
        }

        // click on the versions dropdown or it's options - do nothing
        if (
          event.target instanceof Element &&
          (event.target.classList.contains(SELECT_ITEM_CLASS_TRIGGER) ||
            event.target.classList.contains(SELECT_VERSION_CLASS_TRIGGER))
        ) {
          return
        }

        // open version's dropdown when click on the card
        toggleVersionSelector(true)
        setTimeout(() => {
          versionSelectorRef.current?.openDropdown()
        }, 50)
      }}
      data-testid="application-card-wrapper"
    >
      <WppCard
        value={app.id}
        type="single"
        className={clsx(styles.card, { [styles.cardSelected]: !!selectedVersions.length })}
        checked={!!selectedVersions.length}
        disabled={isDisabled}
        data-testid="application-card"
        size="s"
      >
        <WppTooltip
          className={styles.tooltip}
          header={t('modals.add_from_marketplace.miro_tooltip_disabled.header')!}
          text={t('modals.add_from_marketplace.miro_tooltip_disabled.text')!}
          config={{
            trigger: isDisabled ? 'mouseenter' : 'manual',
            maxWidth: 250,
          }}
        >
          <Flex direction="column" className={styles.wrapper}>
            <Flex gap={10} className={styles.appMainData}>
              <Flex inline direction="column">
                <ApplicationLogo logo={app?.logoUrl} size={40} />
              </Flex>

              <Flex direction="column">
                <Truncate lines={1} type="m-strong" className={styles.colGrey1000} data-testid="product-name">
                  {app.name}
                </Truncate>

                <Flex>
                  {!!app?.category && (
                    <>
                      <WppTypography className={styles.colGrey800} type="xs-body">
                        {NativeAppsCategoryLabel[app.category]}
                      </WppTypography>

                      <span className={styles.divider}>|</span>
                    </>
                  )}

                  <WppTypography type="xs-body" className={styles.colGrey900}>
                    {t('modals.app_create_card.created_by', { ownerName: app.ownerName })}
                  </WppTypography>
                </Flex>
              </Flex>

              {!!appErrors?.length && (
                <Flex className={styles.launchWarning}>
                  <WppTooltip text={t('modals.app_create_issues.launch_tooltip')}>
                    <div
                      onClickCapture={e => {
                        e.stopPropagation()
                        showAppCreateIssuesModal({
                          app,
                          selectedVersions,
                        })
                      }}
                    >
                      <WppIconWarning />
                    </div>
                  </WppTooltip>
                </Flex>
              )}
            </Flex>

            {!!app.shortDescription && (
              <Truncate type="s-body" lines={1} className={styles.description}>
                {app.shortDescription}
              </Truncate>
            )}

            {(app.versions?.length ?? 0) > 1 &&
              (!showVersionSelector ? (
                <WppTag
                  label={t('modals.app_create_card.versions_available', { count: app.versions?.length })}
                  variant="positive"
                  className={styles.versionsTag}
                  data-testid="application-versions-label"
                >
                  <WppIconBranch slot="icon-start" />
                </WppTag>
              ) : (
                <div className="wpp-spacing-8-top">
                  <SelectVersion
                    ref={versionSelectorRef}
                    options={sortedOptions}
                    selectedVersions={selectedVersions}
                    onSelectVersions={onSelectVersions}
                    onHideDropdown={() => {
                      if (!selectedVersions.length) {
                        toggleVersionSelector(false)
                      }
                    }}
                  />
                </div>
              ))}
          </Flex>
        </WppTooltip>
      </WppCard>
    </div>
  )
}
