import { AttachmentMetadata, UserDetails } from '@wpp-open/core'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'

import { useMembersListApi } from 'api/projects/queries/useMembersListApi'
import { useUsersListApi } from 'api/users/queries/useUsersListApi'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useProject } from 'hooks/useProject'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { LinearPhase } from 'pages/project/components/canvas/utils'
import { MayBeNull } from 'types/common/utils'
import { Members, WrikeIntegrationStatus } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ApplicationItem, PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

export interface ResponsibleUser {
  id: string
  email: string
  label: string
  isMember: boolean
  avatarThumbnail?: MayBeNull<AttachmentMetadata>
  role?: ProjectRole
  firstname?: string
  lastname?: string
  avatarUrl: MayBeNull<string>
  wrikeExternalFirstName?: string
  wrikeExternalLastName?: string
}

export const filterMembers = (members: Members[], search: string): Members[] => {
  const lowerSearch = search.toLowerCase().trim()

  return members.filter(
    ({ firstname, lastname, wrikeExternalFirstName, wrikeExternalLastName }) =>
      firstname?.toLowerCase().includes(lowerSearch) ||
      lastname?.toLowerCase().includes(lowerSearch) ||
      wrikeExternalFirstName?.toLowerCase().includes(lowerSearch) ||
      wrikeExternalLastName?.toLowerCase().includes(lowerSearch),
  )
}

const isMembers = (object: any): object is Members => 'role' in object

export const normalizeUserObj = (user: Members | UserDetails): ResponsibleUser => ({
  id: user.id,
  email: user.email.toLowerCase(),
  label: `${user.firstname || (user as Members)?.wrikeExternalFirstName} ${
    user.lastname || (user as Members)?.wrikeExternalLastName
  }`,
  isMember: Boolean((user as Members).projectId),
  avatarThumbnail: user.avatarThumbnail,
  role: isMembers(user) ? user.role : undefined,
  firstname: user.firstname || (user as Members)?.wrikeExternalFirstName,
  lastname: user.lastname || (user as Members)?.wrikeExternalLastName,
  avatarUrl: user.avatarUrl,
  wrikeExternalFirstName: (user as Members).wrikeExternalFirstName,
  wrikeExternalLastName: (user as Members).wrikeExternalLastName,
})

export const getAssignMember = (members: Members[], userEmail?: string): ResponsibleUser[] => {
  if (!userEmail) {
    return []
  }

  const member = members.find(member => isEqualEmails(member.email, userEmail))

  if (!member) {
    return []
  }

  return [normalizeUserObj(member)]
}

// We need to preserve the order of users, so with the help of such a structure we will be able to do it.
// Unique users will be stored in the "values" field, and order will be preserved in the "ids" field
interface UsersMap {
  values: Record<string, ResponsibleUser>
  emails: string[] // here it is better to use ID, but for members and users it is different IDs.
}

export const mergeUsers = (members: Members[], users: UserDetails[]): ResponsibleUser[] => {
  const usersMap = [...members, ...users].reduce<UsersMap>(
    (acc, curr) => {
      if (acc.values[curr.email.toLowerCase()]) {
        return acc
      }

      acc.values[curr.email.toLowerCase()] = normalizeUserObj(curr)
      acc.emails.push(curr.email.toLowerCase())
      return acc
    },
    { values: {}, emails: [] },
  )

  return usersMap.emails.map(email => usersMap.values[email])
}

interface ItemDate {
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
}

export const getDate = (dueDate: (string | undefined)[]): ItemDate => {
  const [startDate, endDate] = dueDate

  if (startDate && endDate) {
    return { startDate, endDate }
  }

  if (startDate || endDate) {
    return { startDate: null, endDate: startDate || endDate }
  }

  return { startDate: null, endDate: null }
}

export const getFormattedDates = (
  dueDate: (string | undefined)[],
): {
  startDate: string | null
  endDate: string | null
} => {
  const dates = dueDate ? getDate(dueDate) : {}

  const formattedDates = {
    startDate: dates.startDate ? format(new Date(dates.startDate), projectDateFormat) : null,
    endDate: dates.endDate ? format(new Date(dates.endDate), projectDateFormat) : null,
  }

  return formattedDates
}

interface BaseFormValues {
  assignUser: ResponsibleUser[]
  dueDate: (string | undefined)[]
  name?: string
  description?: string
  status?: TaskStatus
}

export const useGetBaseFormValues = (item: Omit<LinearPhase | PhaseItem['item'], 'id'>): BaseFormValues => {
  const { members } = useProject()

  const { startDate, endDate, assignUser: userEmail } = item

  const dueDate = useMemo(() => [startDate, endDate].filter(a => a), [startDate, endDate])
  const assignUser = useMemo(() => getAssignMember(members, userEmail), [userEmail, members])
  const name = item.name ?? (item as ApplicationItem).externalAppName
  const description = item.description ?? ''
  const status = (item as ApplicationItem | ActivityItem).task?.status ?? TaskStatus.TO_DO

  return { name, dueDate, assignUser, description, status }
}

export const useSearchUsers = ({ projectId, withWrike }: { projectId: string; withWrike?: boolean }) => {
  const { data: members } = useMembersListApi({
    params: { id: projectId! },
    enabled: !!projectId,
  })

  const [usersSearch, setUsersSearch] = useState('')
  const search = useDebouncedValue(usersSearch.trim(), 300)

  const { data: users, isLoading: isUsersLoading } = useUsersListApi({
    params: { search },
    enabled: !withWrike,
  })

  const filteredMembers = useMemo(
    () =>
      filterMembers(members, search).filter(m =>
        withWrike && !!m?.wrikeIntegrationStatus
          ? [WrikeIntegrationStatus.CONNECTED, WrikeIntegrationStatus.NOT_CONNECTED].includes(m.wrikeIntegrationStatus)
          : true,
      ),
    [members, search, withWrike],
  )

  const usersOptions = useMemo(
    () => mergeUsers(filteredMembers, withWrike ? [] : users.data),
    [filteredMembers, users.data, withWrike],
  )

  return {
    search,
    isUsersLoading,
    usersOptions,
    setUsersSearch,
  }
}
