import { useRef, useEffect } from 'react'

/**
 * For AgGrid in clientSideRowModel only
 * @param enabled
 * @param toggleStickyHeader
 */
export const useStickyTableHeader = (enabled?: boolean, toggleStickyHeader?: boolean) => {
  const originalStyles = useRef({ position: '', top: '', zIndex: '', background: '', borderBottom: '' })

  useEffect(() => {
    if (!enabled) return

    const header = document.querySelector('.ag-header-row') as HTMLDivElement
    if (!header) return

    if (toggleStickyHeader) {
      originalStyles.current.position = header.style.position
      originalStyles.current.top = header.style.top
      originalStyles.current.zIndex = header.style.zIndex

      header.style.position = 'fixed'
      header.style.top = 'var(--wpp-os-header-height)'
      header.style.zIndex = 'var(--wpp-os-header-z-index)'
      header.style.background = 'var(--wpp-white-color)'
      header.style.borderBottom = '1px solid var(--wpp-grey-color-300)'
    } else {
      const original = originalStyles.current
      header.style.position = original.position
      header.style.top = original.top
      header.style.zIndex = original.zIndex
      header.style.background = original.background
      header.style.borderBottom = original.borderBottom
    }
  }, [enabled, toggleStickyHeader])
}
