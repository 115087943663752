// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iG6xc{align-items:center;padding:4px}.Fz85L{padding:6px}.w0th0{max-width:200px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,WAAA,CAGF,OACE,WAAA,CAGF,OACE,eAAA","sourcesContent":[".avatarFilled {\n  align-items: center;\n  padding: 4px;\n}\n\n.avatarEmpty {\n  padding: 6px;\n}\n\n.tooltip {\n  max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarFilled": `iG6xc`,
	"avatarEmpty": `Fz85L`,
	"tooltip": `w0th0`
};
export default ___CSS_LOADER_EXPORT___;
