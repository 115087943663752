import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/Canvas.module.scss'

export const FluidSkeleton = () => (
  <Flex className={styles.canvasContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)
