import { WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactFlowProvider } from 'reactflow'

import { TemplateDetail } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import { Flex } from 'components/common/flex/Flex'
import { UserInfo } from 'components/common/userInfo/UserInfo'
import styles from 'pages/components/templatePreviewModal/TemplatePreviewModal.module.scss'
import FluidCanvas from 'pages/project/components/canvas/fluidCanvas/FluidCanvas'
import { mapCanvasData } from 'pages/project/components/canvas/utils'
import { TemplatePhase } from 'pages/templates/components/templatePhase/TemplatePhase'
import { TemplatePhaseItem } from 'pages/templates/components/templatePhaseItem/TemplatePhaseItem'
import { ProcessType } from 'types/projects/projects'
import { FluidTemplateDetail, LinearTemplateDetail } from 'types/projects/template'
import { FluidWorkflow, Phase as PhaseType, PhaseItem } from 'types/projects/workflow'

export const TemplateView = ({ template }: { template: TemplateDetail }) => {
  const { t } = useTranslation()

  const stats = useMemo(() => {
    return {
      phasesCount: template?.phasesStats.length,
      phaseItemsCount: template?.phasesStats.reduce((acc, { appsCount }) => acc + appsCount, 0),
    }
  }, [template])

  const phasesData = useMemo(() => {
    const phases = ((template as LinearTemplateDetail)?.phases || []) as PhaseType[]
    return mapCanvasData({ phases })
  }, [template])

  const fluidData = useMemo((): MayBeNull<FluidWorkflow> => {
    if (!template) return null

    const { containers = [], connections = [] } = template as FluidTemplateDetail

    return {
      connections: connections.map(connection => ({ ...connection, id: crypto.randomUUID() })),
      containers: containers.map(container => ({ ...container, itemId: container.item.id })),
      items: containers.map(container => container.item),
    }
  }, [template])

  return (
    <Flex className={styles.body} slot="body" direction="column" gap={16}>
      <Flex justify="between">
        <UserInfo
          user={template.createdBy}
          avatarUrl={template.createdBy.avatarUrl}
          prefix={t('common.by')!}
          templateView
        />

        <WppTypography type="s-body" className={styles.stats} data-testid="view-template-modal-stats">
          {template.processType === ProcessType.LINEAR
            ? t('templates.card.stats', { ...stats })
            : t('templates.card.stats_fluid', { count: stats.phaseItemsCount })}
        </WppTypography>
      </Flex>
      <Flex className={styles.container} direction="column" gap={24} data-testid="view-template-workflow">
        {template.processType === ProcessType.LINEAR ? (
          <Flex className={styles.phases} align="start" data-testid="view-template-modal-phases">
            <Flex className={styles.phasesInnerContainer} gap={20}>
              {phasesData.phaseOrder.map((order, index) => {
                const column = phasesData.phases[order]
                const tasks = phasesData.phases[order].itemIds.map(id => phasesData.items[id]) as PhaseItem[]

                return (
                  <TemplatePhase key={column.id} column={column} index={index} variant="primary">
                    <Flex direction="column" gap={12} className={styles.itemsWrapper}>
                      {tasks.map((task, i) => (
                        <TemplatePhaseItem variant="primary" key={task.id} phaseItem={task} index={i} />
                      ))}
                    </Flex>
                  </TemplatePhase>
                )
              })}
            </Flex>
          </Flex>
        ) : (
          <Flex className={clsx(styles.phases)} align="start" data-testid="view-template-modal-phases">
            <ReactFlowProvider>
              <FluidCanvas fluidData={fluidData!} preview templateView />
            </ReactFlowProvider>
          </Flex>
        )}

        {template.description && (
          <WppTypography type="m-body" data-testid="view-template-modal-description">
            {template.description}
          </WppTypography>
        )}

        {!!template.tags?.length && (
          <Flex gap={5} wrap="wrap" data-testid="view-template-modal-tags">
            {template.tags.map((tag, index) => (
              <WppTag key={index} label={tag} variant="neutral" />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
