import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showPhaseDetailsModal,
  useShowPhaseDetailsModal,
} from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal'
import { DetailsModalType } from 'types/common/utils'

export const useOpenPhasePreviewModal = () => {
  const { visible, hide } = useShowPhaseDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, phaseId, appId, activityId } = Object.fromEntries(params.entries()) as {
      view: string
      phaseId: string
      activityId?: string
      appId?: string
    }

    if (view && view === DetailsModalType.PHASE_DETAILS_PREVIEW) {
      showPhaseDetailsModal({ phaseId, appId, activityId, onClose: setParams })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
