// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pIUB4{height:max-content;margin-left:20px}.unfV4{margin-left:0}.bz3Bz{position:relative;min-height:130px}.Ct0Wi{box-sizing:content-box;padding-bottom:80px}.uv8Su{width:348px}.uv8Su.iRQAy{margin-right:20px}.uv8Su.b8pTN{margin-left:20px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/linearCanvas/components/phase/DragPhase.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,gBAAA,CAGF,OACE,aAAA,CAGF,OACE,iBAAA,CACA,gBAAA,CAGF,OAEE,sBAAA,CACA,mBAAA,CAGF,OACE,WAAA,CAEA,aACE,iBAAA,CAGF,aACE,gBAAA","sourcesContent":[".dragPhaseWrapper {\n  height: max-content;\n  margin-left: 20px;\n}\n\n.isPhaseDragging {\n  margin-left: 0;\n}\n\n.column {\n  position: relative;\n  min-height: 130px; // in order to be able to place tasks into empty phases\n}\n\n.isSomeItemDragging {\n  // to avoid reduce of the column minimum height\n  box-sizing: content-box;\n  padding-bottom: 80px;\n}\n\n.phasePlaceholder {\n  width: 348px; // same as set in `Phase.module.scss`\n\n  &.placeholderLeft {\n    margin-right: 20px;\n  }\n\n  &.placeholderRight {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragPhaseWrapper": `pIUB4`,
	"isPhaseDragging": `unfV4`,
	"column": `bz3Bz`,
	"isSomeItemDragging": `Ct0Wi`,
	"phasePlaceholder": `uv8Su`,
	"placeholderLeft": `iRQAy`,
	"placeholderRight": `b8pTN`
};
export default ___CSS_LOADER_EXPORT___;
