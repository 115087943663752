import { WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useAssignMember } from 'hooks/useAssignMember'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal.module.scss'
import { getTagType } from 'pages/project/components/canvas/utils'
import { Phase } from 'types/projects/workflow'

interface Props {
  phase: Phase
}

export const PhaseDetailsModalHeader = ({ phase }: Props) => {
  const { t } = useTranslation()
  const assignMember = useAssignMember(phase?.assignUser)

  return (
    <Flex direction="column" gap={12}>
      <Flex direction="column" gap={12}>
        {phase.description && (
          <WppTypography type="s-body" className={styles.greyColor1000} data-testid="description">
            {phase.description}
          </WppTypography>
        )}
        <Flex gap={24} align="center">
          {assignMember && (
            <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="application-item-assignee" showName />
          )}

          {(phase?.startDate || phase?.endDate) && (
            <Calendar startDate={phase?.startDate} endDate={phase.endDate} data-testid="application-item-dates" />
          )}

          {phase.status && (
            <div>
              <WppTag
                variant={getTagType(phase.status)!.variant as any}
                categoricalColorIndex={getTagType(phase.status)!.cat as any}
                label={t(`project.phase.status.${phase.status}`)}
              />
            </div>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
