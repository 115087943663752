import { WppActionButton, WppIconInfo, WppTypography } from '@platform-ui-kit/components-library-react'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ExternalLinksPreviewList } from 'pages/project/components/canvas/components/externalLink/ExternalLinksPreviewList'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import { useUpdateAppMargin } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/utils'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { DetailsModalType } from 'types/common/utils'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink } from 'types/projects/workflow'

interface Props {
  containerId: string
  activityItem: ActivityItem
  isDisabled: boolean
  childrenCount: number
  colId?: string
  sortedExternalLinks?: ExternalLink[]
  templateView?: boolean
}

export const FluidPreviewActivity: FC<Props> = ({
  containerId,
  activityItem,
  isDisabled,
  sortedExternalLinks,
  childrenCount,
  templateView,
}) => {
  const appsStarRef = useRef<HTMLDivElement>(null)
  const activityCardRef = useRef<HTMLDivElement>(null)
  const [, setSearchParams] = useSearchParams()
  const [hoveredState, setHoveredState] = useState(false)

  const isUIPartEnabled = useUiPartEnabled(false)

  const { name, startDate, endDate, task, files, description } = activityItem
  const assignMember = useAssignMember(activityItem.assignUser)

  useUpdateAppMargin(containerId, activityCardRef, appsStarRef)

  const childPlaceholders = useMemo(() => {
    const list: null[] = []
    list.length = childrenCount
    list.fill(null)
    return list
  }, [childrenCount])

  const openActivityModal = useCallback(
    (id: string) => setSearchParams({ view: DetailsModalType.ACTIVITY_DETAILS_PREVIEW, activityId: id }),
    [setSearchParams],
  )

  return (
    <Flex
      direction="column"
      gap={12}
      className={styles.previewActivity}
      data-testid={`activity-item-preview-${activityItem.id}`}
      ref={activityCardRef}
    >
      <Flex
        direction="column"
        gap={8}
        align="center"
        className={styles.activityPreviewHeader}
        onMouseEnter={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
        data-testid="activity-preview-header"
      >
        <Flex direction="row" align="center" gap={2} className={styles.activityHeaderTextWrapper}>
          <Flex direction="column">
            <Truncate lines={2} type="s-strong" data-testid="activity-name">
              {name}
            </Truncate>

            {isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED && (
              <StatusText statusKey={task?.status} small />
            )}
            {isUIPartEnabled(ProjectPartKey.Dates) && startDate && endDate && (
              <>
                {!(isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED) && (
                  <Calendar
                    startDate={startDate}
                    endDate={endDate}
                    size="xs-body"
                    hideIcon
                    data-testid="canvas-activity-dates"
                  />
                )}
              </>
            )}
          </Flex>

          <Flex justify="end" className={styles.responsiblePerson} gap={12} align="center">
            {hoveredState && !templateView ? (
              <WppActionButton
                slot="trigger-element"
                variant="secondary"
                data-testid="activity-details-action"
                onClick={e => {
                  e.stopPropagation()
                  openActivityModal(activityItem.id)
                }}
              >
                <WppIconInfo slot="icon-start" />
              </WppActionButton>
            ) : (
              <>
                {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && assignMember ? (
                  <ResponsiblePerson assignMember={assignMember} size="xs" hideIcon data-testid="phase-item-assignee" />
                ) : (
                  <div className={styles.responsiblePersonPlaceholder} />
                )}
              </>
            )}
          </Flex>
        </Flex>
        {isUIPartEnabled(ProjectPartKey.Descriptions) && description && (
          <Flex align="start" justify="start" className={styles.descriptionWrapper}>
            <WppTypography className={styles.greyColor800} title={description} type="xs-body" data-testid="description">
              {description}
            </WppTypography>
          </Flex>
        )}
      </Flex>

      {isUIPartEnabled(ProjectPartKey.ActivityFilesAndLinks) && (
        <>
          {!!files.length && (
            <Flex direction="column" data-testid="activity-files">
              <ShowMoreItems maxToShow={2}>
                {files.map(file => (
                  <AttachedFile
                    preview
                    file={file}
                    key={file.id}
                    activityId={activityItem.id}
                    isDisabled={isDisabled}
                    fileAction={templateView}
                  />
                ))}
              </ShowMoreItems>
            </Flex>
          )}
          {!!sortedExternalLinks?.length && (
            <Flex direction="column" className={styles.externalLinksContainer}>
              <ExternalLinksPreviewList
                activityId={activityItem.id}
                links={sortedExternalLinks}
                isDisabled={isDisabled}
              />
            </Flex>
          )}
        </>
      )}

      {!!childPlaceholders.length && (
        <Flex direction="column" ref={appsStarRef} gap={8}>
          {/* extending activity in such manner - to fit it size for all children AppNodes */}
          {childPlaceholders.map((_, idx) => (
            <div key={idx} className={styles.appPreviewPlaceholder} />
          ))}
        </Flex>
      )}
    </Flex>
  )
}
