// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TflRe{opacity:0}.TflRe .ag-root.ag-unselectable{-webkit-user-select:auto;user-select:auto}.TflRe .ag-overlay-wrapper{padding-top:var(--ag-row-height);pointer-events:auto}.TflRe .ag-tooltip{--ag-tooltip-background-color: var(--wpp-white-color)}.TflRe .ag-cell-value{overflow:hidden}.TflRe .ag-header-cell-comp-wrapper .ag-header-cell-comp .wpp-typography{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.c6ChI:not(.infinite-default-cell),.loading-more-row .c6ChI{display:inline-flex;align-items:center}.JHLOL{opacity:1}.LJiJd{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/common/table/Table.module.scss"],"names":[],"mappings":"AAAA,OA8BE,SAAA,CA5BE,gCACE,wBAAA,CAAA,gBAAA,CAGF,2BACE,gCAAA,CACA,mBAAA,CAGF,mBACE,qDAAA,CAGF,sBACE,eAAA,CAKE,yEACE,eAAA,CACA,kBAAA,CACA,sBAAA,CAUV,4DAEE,mBAAA,CACA,kBAAA,CAGF,OACE,SAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  :global {\n    .ag-root.ag-unselectable {\n      user-select: auto;\n    }\n\n    .ag-overlay-wrapper {\n      padding-top: var(--ag-row-height);\n      pointer-events: auto;\n    }\n\n    .ag-tooltip {\n      --ag-tooltip-background-color: var(--wpp-white-color);\n    }\n\n    .ag-cell-value {\n      overflow: hidden;\n    }\n\n    .ag-header-cell-comp-wrapper {\n      .ag-header-cell-comp {\n        .wpp-typography {\n          overflow: hidden;\n          white-space: nowrap;\n          text-overflow: ellipsis;\n        }\n      }\n    }\n  }\n\n  opacity: 0;\n}\n\n// TableInfinite always wraps a loading cellRenderer, so it adds a custom global class instead.\n.customCell:not(:global(.infinite-default-cell)),\n:global(.loading-more-row) .customCell {\n  display: inline-flex;\n  align-items: center;\n}\n\n.ready {\n  opacity: 1;\n}\n\n.clickableRow {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TflRe`,
	"customCell": `c6ChI`,
	"ready": `JHLOL`,
	"clickableRow": `LJiJd`
};
export default ___CSS_LOADER_EXPORT___;
