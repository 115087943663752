import {
  WppDivider,
  WppCard,
  WppTag,
  WppTooltip,
  WppTypography,
  WppActionButton,
  WppIconEyeOff,
  WppIconEyeOn,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { memo, useCallback, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useProject } from 'hooks/useProject'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { AppLaunchWarnings } from 'pages/project/components/canvas/components/appLaunchWarnings/AppLaunchWarnings'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidApplication/FluidApplication.module.scss'
import { ManageItemMenu } from 'pages/project/components/canvas/fluidCanvas/components/manageItemMenu/ManageItemMenu'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { Members } from 'types/members/members'
import { NativeAppVersionType } from 'types/products/nativeApp'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem, PhaseItemType } from 'types/projects/workflow'
import { capitalizeFirstLetter } from 'utils/common'

interface Props {
  application: ApplicationItem
  handleOpenApp?: () => void
  handleTrackOpenApplication: (phaseId: MayBeNull<string | undefined>) => void
  assignMember?: Members
  showAction?: boolean
  isDisabled?: boolean
  activityId?: string
  isInactive?: boolean
}

export const FluidEditableApplication = memo(
  ({
    showAction,
    application,
    handleOpenApp,
    handleTrackOpenApplication,
    assignMember,
    isDisabled,
    activityId,
    isInactive,
  }: Props) => {
    const { name, startDate, endDate, externalAppName, task, externalAppVersions, externalAppVersionId } = application

    const { project } = useProject()

    const { updateItem } = useUpdateItem({
      id: application.id,
      type: PhaseItemType.Application,
      name: application.name,
      projectId: project.id,
      processType: ProcessType.FLUID,
    })

    const toggleAssignee = useCallback(
      async (newAssignee: ResponsibleUser) => {
        const isDeselecting = assignMember?.id === newAssignee.id
        await updateItem({ assignUser: isDeselecting ? null : newAssignee })
      },
      [assignMember, updateItem],
    )

    const changeHidden = (hidden: boolean) => updateItem({ hidden })

    const selectedVersion = useMemo(
      () => externalAppVersions?.find(version => version.id === externalAppVersionId),
      [externalAppVersionId, externalAppVersions],
    )

    return (
      <WppCard
        variant={!!activityId ? 'secondary' : 'primary'}
        className={clsx(styles.itemContainer, { [styles.disabledItem]: isDisabled || isInactive })}
        data-testid={`phase-item-card-${application.id}`}
      >
        <Flex direction="column" gap={8}>
          <Flex inline direction="column" className={styles.logo} justify="center" align="center">
            <ApplicationLogo logo={application?.logoUrl} />
          </Flex>
          <Flex direction="column">
            <Truncate lines={1} title={name || externalAppName} type="m-strong" data-testid="phase-item-name">
              {name || externalAppName}
            </Truncate>
            {task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED ? (
              <StatusText statusKey={task?.status} />
            ) : (
              <WppTypography type="s-body" className={styles.statusPlaceholder} />
            )}
          </Flex>
          <WppDivider />

          {showAction && !isInactive ? (
            <Flex align="center" justify="between" className={styles.inlinePlaceholder}>
              <Flex align="center" gap={4}>
                <SelectPersonInline selectedId={assignMember?.id} onChange={toggleAssignee}>
                  <ResponsiblePerson assignMember={assignMember} size="xs" />
                </SelectPersonInline>

                <SelectDateInline startDate={startDate} endDate={endDate} onChange={dates => updateItem({ dates })} />

                <WppActionButton
                  onClick={() => changeHidden(!application.hidden)}
                  data-testid="application-hide-action"
                >
                  {application.hidden ? (
                    <WppIconEyeOff data-testid="application-hide-off" />
                  ) : (
                    <WppIconEyeOn data-testid="application-hide-on" />
                  )}
                </WppActionButton>
              </Flex>
              {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                <WppTooltip text={selectedVersion.name}>
                  <WppTag variant="neutral" label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())} />
                </WppTooltip>
              )}
            </Flex>
          ) : (
            <Flex gap={16} align="center" justify="between" className={styles.inlinePlaceholder}>
              <Flex gap={10} align="center">
                <ResponsiblePerson assignMember={assignMember} size="xs" />
                <Calendar startDate={startDate} endDate={endDate} />
              </Flex>
              {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                <WppTooltip text={selectedVersion.name}>
                  <WppTag variant="neutral" label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())} />
                </WppTooltip>
              )}
            </Flex>
          )}
        </Flex>
        <Flex slot="actions" align="center" style={{ position: 'relative' }} gap={4}>
          {/* @TODO: Need to fix this, create new component or change the way how we show this  */}
          <AppLaunchWarnings application={application} />
          {!!showAction && (
            <ManageItemMenu
              application={application}
              handleOpenApp={handleOpenApp}
              handleTrackOpenApplication={handleTrackOpenApplication}
              updateStatus={status => updateItem({ status })}
              isInactive={isInactive}
            />
          )}
        </Flex>
      </WppCard>
    )
  },
)
