import {
  WppActionButton,
  WppIconGear,
  WppIconLink,
  WppIconPitch,
  WppListItem,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { BackToProjectsButton } from 'pages/components/backToProjectsButton/BackToProjectsButton'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import { Banner } from 'pages/project/components/banner/Banner'
import { CopyType, useCopyUrlToClipboard } from 'pages/project/hooks/useCoppyUrlToClipboard'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { useOpenPreviewModals } from 'pages/project/hooks/useOpenPreviewModals'
import styles from 'pages/project/Project.module.scss'
import { getTimeFromCreation, PROJECT_SETUP_TIME } from 'pages/project/utils/projectState'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { AdditionalProjectStatus, ProcessType, ProjectStatus } from 'types/projects/projects'
import { projectTabs } from 'utils/project'
import { routesManager } from 'utils/routesManager'

export const Project = () => {
  const { getWorkspaceTree } = useHierarchy()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { project, isInactive, isUserConnectWrike } = useProject()
  const { name, id, status, contextWorkspace, createdAt } = project
  const workspaceData = getWorkspaceTree(contextWorkspace)

  const { isPermitted } = useIsPermitted()
  const { hasRole, isResponsible } = useHasProjectRole()

  useOpenPreviewModals(project)

  const { copyToClipboardAction } = useCopyUrlToClipboard(CopyType.PROJECT)

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isOwner = hasRole([ProjectRole.OWNER]) || isGlobalManage

  const tabs = isGlobalManage
    ? projectTabs
    : projectTabs.filter(({ roles, forResponsible }) => {
        if (forResponsible && isResponsible()) return true
        return !roles || hasRole(roles)
      })

  const currentTab = matchPath(`${routesManager.project.root.pattern}/:tab`, location.pathname)
  const isTabAllowed = tabs.some(({ id }) => id === currentTab?.params.tab)

  const onDelete = useCallback(() => navigate(routesManager.projects.root.getURL()), [navigate])
  const handleOpenWrike = useCallback(() => window.open(project.wrike?.permalink, '_blank'), [project.wrike?.permalink])

  const showWrikeError = isUserConnectWrike === null ? false : !isUserConnectWrike

  const isWorkspaceValid = !!contextWorkspace ? !!workspaceData.length : true
  const isProjectSetupPending = getTimeFromCreation(createdAt) < PROJECT_SETUP_TIME

  const bannerStatus = useMemo(() => {
    if (status !== ProjectStatus.ACTIVE) return status
    if (showWrikeError) return AdditionalProjectStatus.WRIKE_NO_CONNECTED
    if (!isWorkspaceValid) return AdditionalProjectStatus.WORKSPACE_ERROR
    if (isProjectSetupPending) return AdditionalProjectStatus.PROJECT_SETUP_PENDING

    return status
  }, [isProjectSetupPending, isWorkspaceValid, showWrikeError, status])

  if (!currentTab) return <Navigate to={routesManager.project.workflow.getURL({ id })} replace />
  if (!isTabAllowed) return <Navigate to={routesManager.project.workflow.getURL({ id })} replace />

  return (
    <>
      <Banner status={bannerStatus} />
      <Flex
        direction="column"
        className={clsx(styles.container, {
          [styles.containerWithBar]: bannerStatus !== ProjectStatus.ACTIVE,
        })}
      >
        <BackToProjectsButton />
        <Flex direction="column" gap={16} className={styles.headContainer}>
          <Flex justify="between">
            <WppTypography type="3xl-heading" data-testid="project-name">
              {name}
            </WppTypography>

            <Flex justify="between" gap={12} className={styles.actionsWrapper}>
              {project.isWrikeConnected && (
                <WppActionButton variant="secondary" onClick={handleOpenWrike}>
                  <SvgWrikeLogo className={styles.wrikeIcon} />

                  {t('project.page.wrike_connected')}
                </WppActionButton>
              )}
              {isOwner && (
                <>
                  {project.processType === ProcessType.LINEAR && (
                    <WppActionButton
                      onClick={() => navigate(routesManager.project.demo.getURL({ id }))}
                      data-testid="project-demo"
                      variant="secondary"
                    >
                      <WppIconPitch slot="icon-start" />

                      {t('project.demo.title')}
                    </WppActionButton>
                  )}

                  <Flex align="center">
                    <ProjectManageMenu project={project} onDelete={onDelete} manageStatus>
                      <WppListItem
                        data-testid="project-settings-btn"
                        onWppChangeListItem={() => showProjectEditModal({ project: project })}
                        disabled={isInactive}
                      >
                        <WppIconGear slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('project.page.project_settings')}
                        </WppTypography>
                      </WppListItem>

                      <WppListItem data-testid="copy-project-url" onWppChangeListItem={copyToClipboardAction}>
                        <WppIconLink slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('product.copy_project_link')}
                        </WppTypography>
                      </WppListItem>
                    </ProjectManageMenu>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>

          <Flex gap={24}>
            <WppTabs
              // we need to update the component if amount of tabs changed w/o the value change - WPPLONOP-18006
              key={tabs.length}
              value={currentTab?.params.tab || routesManager.project.overview.shortPattern}
              size="s"
              className={styles.nav}
            >
              {tabs.map(({ id, value }) => (
                <Link to={id} key={id} tabIndex={-1}>
                  <WppTab value={id} size="s">
                    {value}
                  </WppTab>
                </Link>
              ))}
            </WppTabs>
          </Flex>
        </Flex>

        <Outlet />
      </Flex>
    </>
  )
}
