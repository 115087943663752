import { HierarchyLevelType, MayBeNull } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'

import { DefaultHierarchyLevelType } from 'types/core'
import { NativeAppVersionDTO } from 'types/products/nativeApp'

// this is the same logic, as currently implemented on the Core side for hierarchy validation
// on projects with "dynamic" hierarchy can be run only apps w/o context requirements
export const isValidHierarchyLevel = (
  version?: MayBeNull<NativeAppVersionDTO>,
  currentWorkspaceLevel?: MayBeNull<HierarchyLevelType>,
): boolean => {
  const requiredHierarchy = (version?.dataContext || []).filter(
    (value: string) =>
      value === HierarchyCustomNodeType ||
      Object.values(DefaultHierarchyLevelType).includes(value as DefaultHierarchyLevelType),
  )

  return !requiredHierarchy.length || requiredHierarchy.includes(currentWorkspaceLevel!)
}
