// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dpWty{position:fixed;top:var(--wpp-os-header-height);left:0;z-index:var(--wpp-os-header-z-index);display:flex;width:-moz-fit-content;width:fit-content;min-width:100%;padding:0 24px;background:var(--wpp-white-color)}.dpWty.ZbXe1{opacity:0;pointer-events:none}.iqQoP{height:100%;padding:8px 12px 12px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,+BAAA,CACA,MAAA,CACA,oCAAA,CACA,YAAA,CACA,sBAAA,CAAA,iBAAA,CACA,cAAA,CACA,cAAA,CACA,iCAAA,CAEA,aACE,SAAA,CACA,mBAAA,CAIJ,OACE,WAAA,CACA,qBAAA","sourcesContent":[".stickySubheader {\n  position: fixed;\n  top: var(--wpp-os-header-height);\n  left: 0;\n  z-index: var(--wpp-os-header-z-index);\n  display: flex;\n  width: fit-content;\n  min-width: 100%;\n  padding: 0 24px;\n  background: var(--wpp-white-color);\n\n  &.headerHidden {\n    opacity: 0;\n    pointer-events: none;\n  }\n}\n\n.stickySubheaderColumn {\n  height: 100%;\n  padding: 8px 12px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickySubheader": `dpWty`,
	"headerHidden": `ZbXe1`,
	"stickySubheaderColumn": `iqQoP`
};
export default ___CSS_LOADER_EXPORT___;
