import {
  WppActionButton,
  WppButton,
  WppIconEnter,
  WppIconPlus,
  WppSkeleton,
  WppTypography,
  WppIconEdit,
} from '@platform-ui-kit/components-library-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGenerateProjectDownloadUrlsApi } from 'api/attachments/queries/useGenerateProjectDownloadUrlsApi'
import { usePresentationApi } from 'api/projects/queries/usePresentationApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { TextViewer } from 'components/richText/textViewer/TextViewer'
import { parseRichText } from 'components/richText/utils'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useProject } from 'hooks/useProject'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { mapLinearApps } from 'pages/project/components/canvas/utils'
import styles from 'pages/projectDemo/components/appPresentation/AppPresentation.module.scss'
import { showEditPresentationModal } from 'pages/projectDemo/components/editAppPresentation/EditAppPresentationModal'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  projectId: string
  appId: string
}

export const AppPresentation: FC<Props> = ({ projectId, appId }) => {
  const { t } = useTranslation()

  const { linearData, fluidData } = useProject()
  const linearAppsMap = useMemo(() => mapLinearApps(linearData), [linearData])

  const application = useMemo(() => {
    if (fluidData) {
      return fluidData.items.find(item => item.id === appId)! as ApplicationItem
    }

    return linearAppsMap[appId]
  }, [appId, fluidData, linearAppsMap])

  const { data: presentation, isLoading: isDataLoading } = usePresentationApi({
    params: { id: application.presentationSlideId! },
    enabled: !!application.presentationSlideId,
  })

  const { data: images, isLoading: isImageLoading } = useGenerateProjectDownloadUrlsApi({
    params: { keys: [presentation?.image?.key as string] },
    enabled: !!presentation?.image,
  })

  const image = images.length > 0 ? images[0] : null
  const richText = presentation?.content ? parseRichText(presentation.content) : null

  const hasData = useMemo(() => {
    return (richText && richText.plain?.length) || !!image
  }, [image, richText])

  const appLaunchErrors = useAppLaunchErrors(application)
  const { handleOpenApplication } = useOpenApplication({ application })

  if (isDataLoading || isImageLoading) {
    return <WppSkeleton variant="rectangle" height="100%" />
  }

  return (
    <div className={styles.container} data-testid="demo-app-info">
      <Flex justify="between">
        <Flex align="center" gap={18}>
          <ApplicationLogo logo={application?.logoUrl} size={24} />

          <WppTypography type="xl-heading" data-testid="demo-app-name">
            {application.name ?? application.externalAppName}
          </WppTypography>
        </Flex>

        <Flex gap={16}>
          {hasData && (
            <WppActionButton
              variant="primary"
              onClick={() => showEditPresentationModal({ projectId, application, presentation })}
              data-testid="edit-demo-content-pencil-icon"
            >
              <WppIconEdit slot="icon-start" />
            </WppActionButton>
          )}
          <WppButton
            onClick={handleOpenApplication}
            data-testid="demo-app-ctx-option"
            size="s"
            disabled={!!appLaunchErrors.length}
          >
            <WppIconEnter slot="icon-start" />

            {t('project.demo.action.launch')}
          </WppButton>
        </Flex>
      </Flex>

      {!hasData ? (
        <EmptyState
          title={t('project.demo.no_data.title')}
          description={t('project.demo.no_data.description')}
          testToken="app-data"
        >
          <Flex direction="column" justify="center">
            <WppActionButton onClick={() => showEditPresentationModal({ projectId, application, presentation })}>
              <WppIconPlus slot="icon-start" />
              {t('project.demo.no_data.add_content')!}
            </WppActionButton>
          </Flex>
        </EmptyState>
      ) : (
        <Flex className={styles.content} direction="column" gap={32}>
          {image && <img src={image.signedUrl} className={styles.image} alt="" data-testid="demo-app-image" />}
          <Flex className={styles.textWrapper} direction="column" gap={24}>
            <WppTypography type="m-body">
              <TextViewer text={richText} />
            </WppTypography>
          </Flex>
        </Flex>
      )}
    </div>
  )
}
