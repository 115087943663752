import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { TaskStatus } from 'types/projects/tasks'

export interface ApplicationPatchItemRequest {
  id: string
  name?: MayBeNull<string>
  description?: MayBeNull<string>
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  assignUser?: MayBeNull<string>
  taskStatus?: MayBeNull<TaskStatus>
  hidden?: boolean
}
export const patchApplicationApi = ({ id, ...body }: ApplicationPatchItemRequest) =>
  projectApi.patch(`/applications/${id}`, body)
