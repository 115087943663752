import {
  WppActionButton,
  WppIconMore,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppIconGear,
  WppIconEnter,
  WppDivider,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { useDeleteApplicationApi } from 'api/canvas/mutation/useDeleteApplicationApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useToast } from 'hooks/useToast'
import { showEditAppModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/EditAppModal'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { queryClient } from 'providers/osQueryClient/utils'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'

interface Props {
  application: ApplicationItem
  handleOpenApp?: () => void
  handleTrackOpenApplication: (phaseId: MayBeNull<string | undefined>) => void
  updateStatus: (status: TaskStatus) => void
  isInactive?: boolean
}

export const ManageItemMenu = ({
  application,
  handleOpenApp,
  handleTrackOpenApplication,
  updateStatus,
  isInactive,
}: Props) => {
  const { t } = useTranslation()

  const appLaunchErrors = useAppLaunchErrors(application)
  const { mutateAsync: handleDeleteApplication } = useDeleteApplicationApi()
  const { showToast } = useToast()

  const handleDelete = async () => {
    try {
      await handleDeleteApplication({ id: application.id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.delete_app', { query: makeStringShorter(application.name) }),
      })
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_remove', { query: 'application' }),
      })
      console.error(e)
    } finally {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    }
  }

  return (
    <WppMenuContext slot="actions" dropdownConfig={{ appendTo: () => document.body }}>
      <WppActionButton slot="trigger-element" variant="secondary">
        <WppIconMore slot="icon-start" direction="horizontal" size="s" />
      </WppActionButton>
      <div>
        <Flex direction="column" style={{ marginBottom: '8px' }}>
          <WppListItem
            onWppChangeListItem={() => {
              handleOpenApp?.()
              handleTrackOpenApplication(null)
            }}
            disabled={!!appLaunchErrors.length}
            data-testid="context-app-launch"
          >
            <WppIconEnter slot="left" />
            <span slot="label">{t('project.item.launch_app')}</span>
          </WppListItem>
          <WppListItem
            onWppChangeListItem={() => showEditAppModal({ application: application })}
            data-testid="context-app-settings"
          >
            <WppIconGear slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('project.item.settings')}
            </WppTypography>
          </WppListItem>
          <div style={{ display: isInactive ? 'none' : 'block' }}>
            <WppListItem
              onWppChangeListItem={() =>
                showDeleteModal({
                  title: t('project.canvas.delete_app_title'),
                  subTitle: t('project.canvas.delete_app'),
                  deleteText: t('project.canvas.delete')!,
                  onDelete: handleDelete,
                })
              }
              data-testid="context-app-remove"
            >
              <WppIconRemoveCircle slot="left" />
              <WppTypography slot="label" type="s-body">
                {t('common.btn_delete')}
              </WppTypography>
            </WppListItem>
          </div>
        </Flex>
        {application.task && (
          <div style={{ display: isInactive ? 'none' : 'block' }}>
            <WppDivider />
            <TaskStatusChangeDropdown
              onChange={status => updateStatus(status)}
              selectedStatus={application.task?.status}
              showConfirm={false}
            />
          </div>
        )}
      </div>
    </WppMenuContext>
  )
}
