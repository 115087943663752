import { useOs } from '@wpp-open/react'
import { useCallback, useEffect, useMemo, MouseEvent } from 'react'
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  Connection,
  Node,
  useReactFlow,
  useStoreApi,
  Background,
  useUpdateNodeInternals,
} from 'reactflow'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { CustomEdge } from 'pages/project/components/canvas/fluidCanvas/components/fluidEdges/CustomEdge'
import { CustomMarker } from 'pages/project/components/canvas/fluidCanvas/components/fluidEdges/CustomMarker'
import { FluidLegend } from 'pages/project/components/canvas/fluidCanvas/components/fluidLegend/FluidLegend'
import { ActivityNode } from 'pages/project/components/canvas/fluidCanvas/components/fluidNodes/ActivityNode'
import 'pages/project/components/canvas/fluidCanvas/FluidCanvas.scss'
import { AppNode } from 'pages/project/components/canvas/fluidCanvas/components/fluidNodes/AppNode'
import {
  EdgeType,
  NodeType,
  mapFluidDataNodes,
  mapFluidEdges,
  EdgePosition,
  ConnectionType,
  splitByUnderscore,
  CustomMarkerType,
  canDropInActivity,
  edgeConnectValidation,
  alignChildren,
  updateNodeEdges,
  canDropOutOfActivity,
  revertNodeOnDrop,
} from 'pages/project/components/canvas/fluidCanvas/utils'
import { useCreateFluidConnection } from 'pages/project/components/canvas/hooks/useCreateFluidConnection'
import { useUpdateFluidApp } from 'pages/project/components/canvas/hooks/useUpdateFluidApp'
import { useUpdateFluidContainer } from 'pages/project/components/canvas/hooks/useUpdateFluidContainer'
import { FluidAppPatchAction } from 'pages/project/components/canvas/utils'
import 'reactflow/dist/style.css'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { FluidWorkflow } from 'types/projects/workflow'

const renderNodes = {
  [NodeType.APP_NODE]: AppNode,
  [NodeType.ACTIVITY_NODE]: ActivityNode,
}
const edgeTypes = {
  [EdgeType.CUSTOM_EDGE]: CustomEdge,
}

interface Props {
  fluidData: FluidWorkflow
  projectId?: string
  preview?: boolean
  templateView?: boolean
  isInactive?: boolean
}

const FluidCanvas = ({ fluidData, preview, templateView, projectId, isInactive }: Props) => {
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const updateNodeInternals = useUpdateNodeInternals()

  const { getIntersectingNodes } = useReactFlow()
  const flowStore = useStoreApi()

  const { updateContainer } = useUpdateFluidContainer({
    projectId: projectId!,
  })
  const { moveApp } = useUpdateFluidApp({ projectId: projectId! })
  const { createConnection } = useCreateFluidConnection({ projectId: projectId! })
  const { isResponsible } = useHasProjectRole()
  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { items, containers, connections } = fluidData

  const itemsMap = useMemo(() => Object.fromEntries(items.map(item => [item.id, item])), [items])

  const {
    osContext: {
      userDetails: { email },
    },
  } = useOs()

  useEffect(() => {
    const mappedNodes = mapFluidDataNodes({
      containers,
      itemsMap,
      preview,
      userEmail: email,
      isOwnerOrGlobal: isOwnerOrGlobalManage,
      templateView,
      isInactive,
    })

    const mappedEdges = mapFluidEdges(
      connections,
      mappedNodes,
      containers,
      itemsMap,
      projectId!,
      email,
      preview,
      isOwnerOrGlobalManage,
      isInactive,
    )

    setEdges(mappedEdges)
    setNodes(mappedNodes)
  }, [
    containers,
    itemsMap,
    setNodes,
    connections,
    setEdges,
    preview,
    email,
    isOwnerOrGlobalManage,
    templateView,
    isInactive,
    projectId,
  ])

  const onConnect = useCallback(
    async (connection: Connection) => {
      const validationError = edgeConnectValidation({
        connection,
      })

      if (!validationError) {
        const [sourceAnchor] = splitByUnderscore(connection.sourceHandle!)
        const [targetAnchor] = splitByUnderscore(connection.targetHandle!)
        const isDataConnection = connection.sourceHandle?.includes(ConnectionType.DATA)

        await createConnection({
          type: isDataConnection ? ConnectionType.DATA : ConnectionType.FLOW,
          sourceId: connection.source!,
          sourceAnchorSide: sourceAnchor as EdgePosition,
          targetId: connection.target!,
          targetAnchorSide: targetAnchor as EdgePosition,
        })
      }
    },
    [createConnection],
  )

  const handleNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== NodeType.APP_NODE && !node.parentNode) return
      const intersectionNodes = getIntersectingNodes(node)
      const activityNode = intersectionNodes.filter(node => node.type === NodeType.ACTIVITY_NODE)[0]

      if (!activityNode || !canDropInActivity(node, activityNode, edges)) {
        setNodes(nodes =>
          nodes.map(prevNode => {
            if (prevNode.className === 'highlight') {
              return {
                ...prevNode,
                className: '',
              }
            }
            return prevNode
          }),
        )
        return
      }

      setNodes(nodes =>
        nodes.map(prevNode => {
          if (prevNode.type === NodeType.ACTIVITY_NODE) {
            return {
              ...prevNode,
              className: activityNode.id === prevNode.id ? 'highlight' : '',
            }
          }

          return prevNode
        }),
      )
    },
    [edges, getIntersectingNodes, setNodes],
  )

  const handleNodeDragStop = useCallback(
    async (_e: MouseEvent, droppedNode: Node) => {
      // app, that is moving in/out/inside activity
      if (droppedNode.type === NodeType.APP_NODE) {
        const intersectionNodes = getIntersectingNodes(droppedNode)
        const activityNode = intersectionNodes.filter(node => node.type === NodeType.ACTIVITY_NODE)[0]

        // prevent drop apps with flow connections
        if (activityNode && !canDropInActivity(droppedNode, activityNode, edges)) {
          setNodes(prevNodes => revertNodeOnDrop(droppedNode, prevNodes))
          return
        }

        // reorder in activity
        if (activityNode && droppedNode.parentNode === activityNode.id) {
          const {
            position: { x, y },
            data: {
              oldPosition: { x: oldX, y: oldY },
            },
          } = droppedNode

          if (x === oldX && y === oldY) return

          setNodes(prevNodes => alignChildren(prevNodes, activityNode.id))

          const children = nodes
            .filter(({ parentNode }) => activityNode.id === parentNode)
            .sort(({ position: positionA }, { position: positionB }) => {
              return positionA.y === positionB.y ? 0 : positionA.y < positionB.y ? -1 : 1
            })

          const orderNumber = children.findIndex(child => child.id === droppedNode.id)
          await moveApp(droppedNode, droppedNode.position, {
            id: droppedNode.data.item.id,
            activityId: activityNode.data.item.id,
            orderNumber,

            fluidOptimisticAction: FluidAppPatchAction.Reorder,
          })
          return
        }

        // drop IN activity
        if (activityNode) {
          let sourceParentNode: string | undefined
          const newNodes = flowStore
            .getState()
            .getNodes()
            .map(prevNode => {
              if (prevNode.id === droppedNode.id) {
                sourceParentNode = prevNode.parentNode
                return {
                  ...prevNode,
                  parentNode: activityNode.id,
                  position: {
                    // where we drop
                    x: droppedNode.positionAbsolute?.x! - activityNode.position.x,
                    y: droppedNode.positionAbsolute?.y! - activityNode.position.y,
                  },
                }
              }
              return prevNode
            })

          let sortedNewNodes = alignChildren(newNodes, activityNode.id)
          if (sourceParentNode) {
            // update UI state if children was moved out from activity
            sortedNewNodes = alignChildren(sortedNewNodes, sourceParentNode)
          }
          // refresh-align children on UI
          setNodes(sortedNewNodes)

          setEdges(prevEdges => updateNodeEdges(droppedNode, sortedNewNodes, prevEdges))
          updateNodeInternals(droppedNode.id)

          // find order number of the dropped item
          const children = sortedNewNodes
            .filter(({ parentNode }) => activityNode.id === parentNode)
            .sort(({ position: positionA }, { position: positionB }) => {
              return positionA.y === positionB.y ? 0 : positionA.y < positionB.y ? -1 : 1
            })

          const orderNumber = children.findIndex(child => child.id === droppedNode.id)

          const {
            position: { x, y },
            data: {
              oldPosition: { x: oldX, y: oldY },
            },
          } = droppedNode

          if (x === oldX && y === oldY) return

          await moveApp(droppedNode, droppedNode.position, {
            id: droppedNode.data.item.id,
            activityId: activityNode.data.item.id,
            orderNumber,

            fluidOptimisticAction: FluidAppPatchAction.MoveIn,
          })

          return
        }

        // drop OUT OF activity - only if it is set
        if (droppedNode.parentNode) {
          if (!isOwnerOrGlobalManage && !canDropOutOfActivity(droppedNode)) {
            setNodes(prevNodes => revertNodeOnDrop(droppedNode, prevNodes))
            return
          }

          const innerOffset = parseFloat(
            document.body.style.getPropertyValue(`--inner-start-${droppedNode.parentNode}`) || '0',
          )

          const positionAbsolute = droppedNode.positionAbsolute!
          positionAbsolute.y += innerOffset

          const nodesNoParent = flowStore
            .getState()
            .getNodes()
            .map(prevNode => {
              if (prevNode.id === droppedNode.id) {
                prevNode.parentNode = undefined
                prevNode.position = positionAbsolute
                prevNode.style = { ...(prevNode.style ?? {}), marginTop: 0 }
              }
              return prevNode
            })
          const newNodes = alignChildren(nodesNoParent, droppedNode.parentNode!)

          setNodes(newNodes)
          setEdges(prevEdges => updateNodeEdges(droppedNode, newNodes, prevEdges))
          updateNodeInternals(droppedNode.id)

          const { x, y } = positionAbsolute

          await moveApp(droppedNode, positionAbsolute, {
            id: droppedNode.data.item.id,
            activityId: null,

            projectId,
            coordinateX: x,
            coordinateY: y,

            fluidOptimisticAction: FluidAppPatchAction.MoveOut,
          })

          return
        }
      }

      // moving usual app or activity on the canvas
      await updateContainer(droppedNode)
    },
    [
      updateContainer,
      getIntersectingNodes,
      edges,
      setNodes,
      nodes,
      moveApp,
      flowStore,
      setEdges,
      isOwnerOrGlobalManage,
      updateNodeInternals,
      projectId,
    ],
  )

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDragStop={handleNodeDragStop}
        onNodeDrag={handleNodeDrag}
        onConnect={onConnect}
        nodeTypes={renderNodes}
        fitView
        minZoom={0.3}
        edgeTypes={edgeTypes}
        deleteKeyCode={null}
        id="fluid-canvas"
      >
        <CustomMarker id={CustomMarkerType.MARKER_PRIMARY} color="var(--wpp-dataviz-color-seq-brand-500)" />
        <CustomMarker id={CustomMarkerType.MARKER_SECONDARY} color="var(--wpp-dataviz-color-seq-grey-500)" />
        <Controls showInteractive={(isResponsible() || isOwnerOrGlobalManage) && !preview} />
        <Background color="var(--wpp-dataviz-color-seq-grey-400)" gap={16} />
        <FluidLegend />
      </ReactFlow>
    </>
  )
}

export default FluidCanvas
