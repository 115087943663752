// TODO should be replace with `export { DefaultHierarchyLevelType } from '@wpp-open/core'`, when `@wpp-open/core` will
//  include Region and Industry

export enum DefaultHierarchyLevelType {
  Tenant = 'TENANT',
  Client = 'CLIENT',
  Market = 'MARKET',
  Brand = 'BRAND',
  Region = 'REGION',
  Industry = 'INDUSTRY',
}
