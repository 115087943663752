import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppInput,
  WppFilterButton,
  WppCard,
  WppTypography,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppListItem,
  WppIconEyeOn,
  WppIconFolder,
  WppIconLink,
  WppSelect,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useCopyToClipboard, useLocalStorage } from 'react-use'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { useProjectsApi } from 'api/projects/queries/useFetchProjectsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, tableActions } from 'components/common/table'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { TablePageInfinite } from 'components/common/table/tableInfinite/TablePageInfinite'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHierarchy } from 'hooks/useHierarchy'
import { useStableCallback } from 'hooks/useStableCallback'
import { useToast } from 'hooks/useToast'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import styles from 'pages/dashboard/components/tasksDashboard/TasksDashboard.module.scss'
import { useTasksListLoader } from 'pages/dashboard/components/tasksDashboard/useTasksListLoader'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { useOpenDashboardTaskPreviewModal } from 'pages/project/hooks/useOpenDashboardTaskPreviewModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { DetailsModalType } from 'types/common/utils'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { ProjectStatus } from 'types/projects/projects'
import { TasksFilter, TaskStatus } from 'types/projects/tasks'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

export const FiltersSkeleton = () => (
  <Flex direction="row" gap={16} className={styles.skeletonContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="300px" />
    <WppSkeleton variant="rectangle" height="100%" width="240px" />
    <WppSkeleton variant="rectangle" height="100%" width="99px" />
  </Flex>
)

const gridOptions = {
  rowStyle: { cursor: 'pointer' },
}

export const initialTasksFilters: TasksFilter = {
  search: '',
  archived: false,
  dueDateRanges: [],
  selectedProjects: [],
}

const filtersCount = ({ search, archived, dueDateRanges, selectedProjects, workspace, ...hierarchy }: TasksFilter) => {
  const restCount = Object.values(hierarchy).reduce((acc, curr) => acc + (curr?.length || 0), 0)

  return (selectedProjects as any[]).concat(dueDateRanges).length + restCount + Number(archived)
}

export const TasksDashboard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [, copyToClipboard] = useCopyToClipboard()
  const { hierarchyOrder } = useHierarchy()
  const [, setSearchParams] = useSearchParams()
  const [isEmpty, setIsEmpty] = useState(false)
  const [projectsExist, setProjectsExist] = useState(false)
  const { showToast } = useToast()
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [hideSubHeader, setHideSubHeader] = useState(false)
  useOpenDashboardTaskPreviewModal()

  const {
    osContext: { userDetails },
  } = useOs()
  const { data: projects, isLoading } = useProjectsApi({ params: { status: [ProjectStatus.ACTIVE] } })
  const [taskFilters, setTaskFilters] = useLocalStorage<TasksFilter>(`dstasks:${userDetails.id}`, {} as TasksFilter)
  const [filters, setFilters] = useState<TasksFilter>({
    ...initialTasksFilters,
    ...hierarchyOrder.reduce(
      (pre, curr) => ({
        ...pre,
        [curr]: [],
      }),
      {},
    ),
    ...taskFilters,
  })
  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()
  const { loader } = useTasksListLoader({ filters })

  const filtersCounts = useMemo(() => filtersCount(filters), [filters])

  useEffect(() => {
    const { search, ...rest } = filters
    setTaskFilters(prev => {
      return { ...prev, ...rest }
    })
  }, [setTaskFilters, filters])

  const updateStatus = useCallback(
    async (id: string, status: TaskStatus) => {
      await handleUpdateTask({ id, status })

      await queryClient.invalidateQueries([ApiQueryKeys.TASKS_FETCHER])
      tableActions.reload([TableKey.TASKS_LIST])
    },
    [handleUpdateTask],
  )

  const copyToClipboardAction = useCallback(
    (text: string) => {
      copyToClipboard(
        `${window.location.href}?${createSearchParams({
          view: DetailsModalType.DASHBOARD_TASK_DETAILS_PREVIEW,
          id: text,
        })}`,
      )
      showToast({ type: 'success', message: t('project.tasks.toast_copy_to_clipboard_success_message')! })
    },
    [copyToClipboard, showToast, t],
  )

  const openTaskModal = useCallback(
    (id: string) => setSearchParams({ view: DetailsModalType.DASHBOARD_TASK_DETAILS_PREVIEW, id }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseTasksFilterModal = (newFilter: TasksFilter) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={isEmpty ? t('tasksDashboard.table.empty_state_no_tasks') : t('common.no_search_results')}
        testToken="tasks"
        filtersApplied={!isEmpty}
        description={
          isEmpty ? t('tasksDashboard.table.empty_state_no_tasks_description') : t('common.no_results_description')
        }
      />
    )
  }, [isEmpty, t])

  const columnDefs = useMemo<ColDef<TaskDashboard>[]>(() => {
    return [
      {
        colId: 'task_name',
        flex: 2,
        headerName: t('tasksDashboard.table.task_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.name} data-testid="task_name">
            {data!.name}
          </WppTypography>
        ),
      },
      {
        colId: 'project_name',
        flex: 2,
        headerName: t('tasksDashboard.table.project_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.projectName} data-testid="project_name">
            {data!.projectName}
          </WppTypography>
        ),
      },
      {
        colId: 'status',
        flex: 1,
        headerName: t('tasksDashboard.table.status')!,
        valueFormatter: ({ data }) => t(`project.tasks.status.${data?.status}`),
      },
      {
        colId: 'dueDate',
        flex: 1,
        headerName: t('tasksDashboard.table.due_date')!,
        cellRenderer: ({ data }) => <Calendar startDate={data?.startDate} endDate={data?.endDate} size="s-body" />,
      },
      ...hierarchyOrder.reduce(
        (prev, curr) => [
          ...prev,
          {
            colId: curr,
            flex: 1,
            headerName: t(`modals.create_project.field_${curr}_label`, { defaultValue: curr })!,
            cellRenderer: ({ data }) => {
              const hierarchy = data?.projectContextHierarchy?.find(h => h.title.toLowerCase() === curr.toLowerCase())

              return (
                <Truncate type="s-body" className={styles.overflow} title={hierarchy?.name || ''}>
                  {hierarchy?.name || '-'}
                </Truncate>
              )
            },
          },
        ],
        [] as ColDef<TaskDashboard>[],
      ),
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => (
          <WppMenuContext
            className={styles.rowContextMenu}
            slot="actions"
            dropdownConfig={{
              appendTo: () => document.body,
              placement: 'bottom-end',
            }}
          >
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
            <Flex direction="column" gap={4}>
              <WppListItem
                onWppChangeListItem={() => openTaskModal(data!.id)}
                data-testid="task-details-context-action"
              >
                <WppIconEyeOn slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.view_details_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() => navigate(routesManager.project.tasks.getURL({ id: data!.projectId }))}
                data-testid="open-project-context-action"
              >
                <WppIconFolder slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.open_project_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() => copyToClipboardAction(data!.id)}
                data-testid="copy-task-link-context-action"
              >
                <WppIconLink slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.copy_task_link_action')}
                </WppTypography>
              </WppListItem>

              {data?.projectStatus === ProjectStatus.ACTIVE && (
                <TaskStatusChangeDropdown
                  onChange={status => updateStatus(data!.id, status)}
                  selectedStatus={data?.status!}
                  showConfirm={false}
                />
              )}
            </Flex>
          </WppMenuContext>
        ),
      },
    ]
  }, [t, hierarchyOrder, openTaskModal, navigate, copyToClipboardAction, updateStatus])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<TaskDashboard>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target)) {
      openTaskModal(data!.id)
    }
  })

  return (
    <Flex direction="column" className={styles.container}>
      <PageBackToTop stickyTableHeader onChangeState={setHideSubHeader} />
      <DashboardNavigation hideSubHeader={hideSubHeader} />

      <div className={styles.viewContainer}>
        {!isEmpty && (
          <Flex className={styles.filtersContainer}>
            {isTableLoading ? (
              <FiltersSkeleton />
            ) : (
              <Flex gap={16}>
                <WppInput
                  size="s"
                  name="search"
                  placeholder={t('dashboard.field_search_placeholder')!}
                  onWppChange={setSearchDebounced}
                  type="search"
                  data-testid="dashboard-tasks-search"
                  className={styles.searchInput}
                />

                <WppSelect
                  size="s"
                  placeholder={t('tasksDashboard.project_select_placeholder')}
                  className={styles.select}
                  value={filters.selectedProjects}
                  onWppChange={e =>
                    setFilters({ ...filters, selectedProjects: e.detail.value === 'none' ? '' : e.detail.value })
                  }
                  type="multiple"
                  withFolder
                  withSearch
                  loading={isLoading}
                  data-testid="projects-select"
                >
                  {projects.map(({ id, name }) => (
                    <WppListItem key={id} value={id}>
                      <p slot="label">{name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>

                <WppFilterButton
                  counter={filtersCounts}
                  onClick={() =>
                    showTasksFiltersModal({ filters, onFiltersSave: handleCloseTasksFilterModal, withHierarchy: true })
                  }
                  data-testid="tasks-filter-button"
                >
                  {t('dashboard.btn_filter')!}
                </WppFilterButton>
              </Flex>
            )}
          </Flex>
        )}
        <WppCard className={clsx(styles.card, { [styles.emptyCard]: !projectsExist })}>
          <TablePageInfinite
            gridOptions={gridOptions}
            tableKey={TableKey.TASKS_LIST}
            loader={loader}
            cacheBlockSize={50}
            rowHeight={48}
            columnDefs={columnDefs}
            onRowClicked={handleOnRowClicked}
            noRowsOverlayComponent={noRowsOverlayComponent}
            headerHeight={isEmpty ? 0 : undefined}
            onLoadSuccess={({ isEmptySource }) => {
              setIsTableLoading(false)
              setProjectsExist(!isEmptySource)
              setIsEmpty(isEmptySource && !filters?.search && !filtersCounts && !filters?.selectedProjects?.length)
            }}
            className={clsx({ [styles.hideBorder]: isEmpty })}
          />
        </WppCard>
      </div>
    </Flex>
  )
}
