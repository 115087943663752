import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useTrackAction } from 'hooks/useAnalytics'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { getEventTrackParams } from 'utils/getEventTrackParams'
import { simulateLinkClick } from 'utils/link'

interface Props {
  application: ApplicationItem
}

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation },
    osContext: { userDetails },
  } = useOs()

  const projectContext = useProject()
  const project = projectContext?.project
  const linearData = projectContext?.linearData

  const { trackAction } = useTrackAction()

  const { externalAppVersions, externalAppVersionId } = application

  const selectedVersion = useMemo(
    () => externalAppVersions?.find(version => version.id === externalAppVersionId),
    [externalAppVersionId, externalAppVersions],
  )

  const openApp = navigation.openApp
  const appLaunchErrors = useAppLaunchErrors(application)

  const handleOpenApplication = useCallback(async () => {
    const { name } = application

    if (!project) {
      console.warn(`Can't run "${name}" application, project is absent.`)
      return
    }

    const canRunApp = !appLaunchErrors.length
    if (!canRunApp) {
      console.warn(`Can't run "${name}" application, please check app configuration.`)
      return
    }

    if (application.type === NativeAppType.EXTERNAL_LINK) {
      simulateLinkClick({ href: application.externalAppVersions[0].config.url!, target: '_blank' })
      return
    }

    openApp(application.appInstanceId)
  }, [application, appLaunchErrors, openApp, project])

  const handleTrackOpenApplication = (phaseId: MayBeNull<string | undefined>) => {
    const { contextHierarchy } = project

    const contextHierarchyMapping: { [key: string]: string } = contextHierarchy.reduce(
      (acc, { title, name }) => ({ ...acc, [title]: name }),
      {},
    )

    const isNoCodeApp = application.type !== NativeAppType.NATIVE

    const data = getEventTrackParams({
      contextHierarchyMapping,
      application,
      userDetails,
      isNoCodeApp,
      selectedVersion,
      project,
      phaseId,
      linearData,
    })

    trackAction(ACTION_ANALYTICS.APPLICATION_LAUNCH, data)
  }

  return { handleOpenApplication, handleTrackOpenApplication }
}
