import clsx from 'clsx'
import React, { memo, useMemo } from 'react'
import { Edge, Handle, Position, ReactFlowState, useReactFlow, useStore } from 'reactflow'

import { FluidActivity } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidActivity'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidNodes/ActivityNode.module.scss'
import { ConnectionType, EdgePosition, splitByUnderscore } from 'pages/project/components/canvas/fluidCanvas/utils'
import { ActivityItem } from 'types/projects/workflow'

interface Props {
  id: string
  selected: boolean
  isConnectable?: boolean
  data: {
    editMode: boolean
    item: ActivityItem
    label: string
    preview?: boolean
    templateView?: boolean
    isInactive?: boolean
  }
}

const connectionNodeIdSelector = ({ connectionNodeId }: ReactFlowState): string | null => connectionNodeId
const connectionNodeType = ({ connectionHandleId }: ReactFlowState) => connectionHandleId
const getChildrenCountSelector =
  (id: string, preview: boolean) =>
  ({ getNodes }: ReactFlowState) => {
    const nodes = getNodes()

    return nodes.filter(node => node.parentNode === id).filter(node => (preview ? !node.data.item.hidden : true)).length
  }

export const ActivityNode = memo(({ id, data, selected, isConnectable }: Props) => {
  const { getEdges } = useReactFlow()
  const connectionNodeId = useStore(connectionNodeIdSelector)
  const connectionHandleId = useStore(connectionNodeType)
  // we need `childrenCount` from the Store, because sometimes `activity.items` contains ghost items
  const childrenCount = useStore(getChildrenCountSelector(id, !!data.preview))

  const isHandleData = useMemo(() => {
    return connectionHandleId?.includes(ConnectionType.DATA)
  }, [connectionHandleId])

  const isTarget = useMemo(() => {
    if (!connectionHandleId) return

    return connectionNodeId
  }, [connectionNodeId, connectionHandleId])

  const nodeTargetConnectionHandlers = useMemo(() => {
    if (!connectionHandleId) return {}

    const edges = getEdges()
    const connectedEdges = edges.filter((edge: Edge) => {
      return (
        (edge.source === connectionNodeId || connectionNodeId === edge.target) &&
        edge.target === id &&
        edge.targetHandle?.includes(ConnectionType.FLOW)
      )
    })

    return connectedEdges.reduce<{ [key in EdgePosition]?: true }>((acc, edge) => {
      const targetAnchor = splitByUnderscore(edge.targetHandle!)[0] as EdgePosition
      acc[targetAnchor] = true
      return acc
    }, {})
  }, [connectionHandleId, connectionNodeId, getEdges, id])

  const hiddenTargetConnectionHandle = !isTarget || connectionNodeId === id || isHandleData || !isConnectable

  const hiddenSourceConnectionHandle =
    isTarget || !selected || connectionNodeId === id || isHandleData || data.preview || !isConnectable

  const isConnectableHandleProps = useMemo(() => {
    return {
      isConnectable,
      isConnectableStart: isConnectable,
      isConnectableEnd: isConnectable,
    }
  }, [isConnectable])

  return (
    <div
      className={clsx({
        [styles.nodeCard]: true,
        [styles.disabledState]: connectionNodeId && !isTarget && isHandleData,
      })}
      data-testid={`activity-fluid-node-${id}`}
    >
      <Handle
        type="target"
        position={Position.Top}
        id={`${EdgePosition.TOP}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.topHandle]: true,
          [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.TOP],
        })}
        data-testid="top-target-anchor"
      />
      <Handle
        type="target"
        position={Position.Right}
        id={`${EdgePosition.RIGHT}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.rightHandle]: true,
          [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.RIGHT],
        })}
        data-testid="right-target-anchor"
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id={`${EdgePosition.BOTTOM}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.bottomHandle]: true,
          [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.BOTTOM],
        })}
        data-testid="bottom-target-anchor"
      />

      <Handle
        type="target"
        position={Position.Left}
        id={`${EdgePosition.LEFT}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.leftHandle]: true,
          [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.LEFT],
        })}
        data-testid="left-target-anchor"
      />

      <Handle
        type="source"
        position={Position.Top}
        id={`${EdgePosition.TOP}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.topHandle]: true,
          [styles.hiddenHandle]: hiddenSourceConnectionHandle,
          [styles.selectedConnectionHandle]: selected,
        })}
        data-testid="top-source-anchor"
      />

      <Handle
        type="source"
        position={Position.Right}
        id={`${EdgePosition.RIGHT}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.rightHandle]: true,
          [styles.hiddenHandle]: hiddenSourceConnectionHandle,
          [styles.selectedConnectionHandle]: selected,
        })}
        data-testid="right-source-anchor"
      />

      <Handle
        type="source"
        position={Position.Bottom}
        id={`${EdgePosition.BOTTOM}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.bottomHandle]: true,
          [styles.hiddenHandle]: hiddenSourceConnectionHandle,
          [styles.selectedConnectionHandle]: selected,
        })}
        data-testid="bottom-source-anchor"
      />

      <Handle
        type="source"
        position={Position.Left}
        id={`${EdgePosition.LEFT}_${ConnectionType.FLOW}`}
        {...isConnectableHandleProps}
        className={clsx({
          [styles.connectionHandle]: true,
          [styles.leftHandle]: true,
          [styles.hiddenHandle]: hiddenSourceConnectionHandle,
          [styles.selectedConnectionHandle]: selected,
        })}
        data-testid="left-source-anchor"
      />
      <FluidActivity
        containerId={id}
        activity={data.item}
        isEditable={data.editMode}
        preview={data.preview}
        templateView={data.templateView}
        childrenCount={childrenCount}
        isInactive={data.isInactive}
      />
    </div>
  )
})
