import {
  WppTypography,
  WppButton,
  WppActionButton,
  WppIconReset,
  WppCheckbox,
} from '@platform-ui-kit/components-library-react'
import { HierarchyNode } from '@wpp-open/core'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useHierarchy } from 'hooks/useHierarchy'
import { HierarchyFilterControl } from 'pages/dashboard/components/hierarchyFilterControl/HierarchyFilterControl'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import styles from 'pages/project/components/files/Files.module.scss'
import { initialTasksFilters } from 'pages/project/components/tasks/Tasks'
import { datesRangeMapping } from 'pages/project/components/tasks/utils'
import { TasksFilter } from 'types/projects/tasks'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  withHierarchy?: boolean
  filters?: TasksFilter
  onFiltersSave: (filters: TasksFilter) => void
}

export const TasksFiltersModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  filters,
  onFiltersSave,
  withHierarchy,
}: Props) => {
  const form = useForm({ defaultValues: filters })
  const { osContext } = useOs()
  const { hierarchyOrder } = useHierarchy()

  const { mapping } = osContext.navigationTree
  const { hierarchyLevels } = osContext.tenant
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const allowNavigationTypes = useMemo(() => osContext.tenant.hierarchyLevels.map(lvl => lvl.type), [osContext])

  const onSubmit = handleSubmit(value => {
    if (withHierarchy) {
      const { children } = mapping[HierarchyContainerNodeId]

      const isWorkspaceEmpty = Object.entries(value)
        .filter(([key]) => hierarchyOrder.includes(key))
        .some(([, value]) => !!value.length)

      if (!isWorkspaceEmpty) {
        onFiltersSave({ ...value, workspace: undefined })
        onClose()
        return
      }

      const getType = (node: HierarchyNode) =>
        (node.type === HierarchyCustomNodeType ? node.customTypeName : node.type)!

      const getAllChildren = (nodes: string[], isParentSelected = false) => {
        return nodes.reduce((acc: string[], curNodeId: string): string[] => {
          const currentNode = mapping[curNodeId] as HierarchyNode
          const currentNodeChildren = currentNode?.children || []

          // We need only HierarchyLevelType projects
          if (!allowNavigationTypes.includes(getType(currentNode))) return acc

          // Get selected nodes from form value
          const selectedNodes = ((value[getType(currentNode).toLowerCase() as keyof TasksFilter] as string[]) || [])
            .map(el => el.split(','))
            .flat(Infinity) as string[]

          const checkIfNodeHasSelectedChildren = (currentHierarchyIndex: number) => {
            const selectedOnNextLvl = []
            for (let index = currentHierarchyIndex + 1; index < hierarchyLevels.length; index++) {
              const lvl = hierarchyLevels[index].type
              const nextLvlValue = (value as any)[lvl.toLowerCase()]

              selectedOnNextLvl.push(!!nextLvlValue?.length)
            }

            return selectedOnNextLvl.some(Boolean)
          }

          const currentHierarchyIndex = hierarchyLevels.findIndex(h => h.type === getType(currentNode))
          const nodeHasSelectedChildren = checkIfNodeHasSelectedChildren(currentHierarchyIndex)

          if (!!selectedNodes.length) {
            const isThisNodeSelected = selectedNodes.includes(currentNode.azId)

            if (isThisNodeSelected) {
              if (!!currentNodeChildren.length) {
                return acc.concat(
                  nodeHasSelectedChildren ? [] : currentNode.azId,
                  getAllChildren(currentNodeChildren, true),
                )
              } else {
                if (currentHierarchyIndex === hierarchyLevels.length - 1) return acc.concat(currentNode.azId)
                return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
              }
            } else return acc
          } else {
            if (!!currentNodeChildren.length) {
              return isParentSelected
                ? acc.concat(
                    nodeHasSelectedChildren ? [] : currentNode.azId,
                    getAllChildren(currentNodeChildren, isParentSelected),
                  )
                : acc.concat(getAllChildren(currentNodeChildren, isParentSelected))
            } else {
              if (currentHierarchyIndex >= hierarchyLevels.length - 1)
                return isParentSelected ? acc.concat(currentNode.azId) : acc

              return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
            }
          }
        }, [])
      }

      onFiltersSave({ ...value, workspace: getAllChildren(children) })
      onClose()
    } else {
      onFiltersSave(value)
      onClose()
    }
  })

  const dueDatesOptions = useMemo(() => {
    return Object.entries(datesRangeMapping).map(([key, value]) => ({
      value: key,
      label: t(value),
    }))
  }, [t])

  const onReset = () => {
    reset({
      ...initialTasksFilters,
      ...hierarchyOrder.reduce(
        (pre, curr) => ({
          ...pre,
          [curr]: [],
        }),
        {},
      ),
    })
  }

  const watchAllFields = watch()
  const { search, ...allOtherFields } = watchAllFields

  const filtersCounts = useMemo(() => {
    return getAppliedFilters(allOtherFields)
  }, [allOtherFields])

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="tasks-filter-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.tasks.filters.title')}
        </WppTypography>
        <div slot="body">
          <Flex direction="column" className={styles.filtersWrapper} gap={16}>
            <FormSelect
              name="dueDateRanges"
              type="multiple"
              data-testid="due-date-select"
              options={dueDatesOptions}
              labelConfig={{ text: t('project.tasks.filters.field_type_label') }}
              placeholder={t('project.tasks.filters.field_type_placeholder')!}
              required
              withFolder
              withSearch
            />
            {withHierarchy && <HierarchyFilterControl />}
            <WppCheckbox
              checked={getValues('archived')}
              labelConfig={{ text: t('project.tasks.filters.archived_checkbox') }}
              required
              onWppChange={({ detail: { checked } }) => setValue('archived', checked)}
              data-testid="archive-tasks-checkbox"
              name="archived"
            />
          </Flex>
        </div>
        <Flex justify="between" slot="actions">
          <Flex>
            {filtersCounts > 0 && (
              <WppActionButton variant="primary" onClick={onReset}>
                <WppIconReset className={styles.resetIcon} />
                {t('common.btn_reset')}
              </WppActionButton>
            )}
          </Flex>
          <Flex gap={12}>
            <WppButton size="m" variant="secondary" onClick={onClose}>
              {t('common.btn_cancel')}
            </WppButton>
            <WppButton size="m" type="submit" variant="primary" loading={isSubmitting}>
              {t('common.btn_apply')}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showTasksFiltersModal } = createNiceModal<Props>(TasksFiltersModal, 'tasks-filters-modal')
